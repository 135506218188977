import { useLocation } from '@reach/router';
import React from 'react';
import NotFoundPage from './notfound';
import PasswordResetPage from './passwordreset';
import VerifyEmailPage from './verifyemail';

const ActionMode = {
  RESET_PASSWORD: 'resetPassword',
  VERIFY_EMAIL: 'verifyEmail',
  // Implementing this is not required.
  RECOVER_EMAIL: 'recoverEmail',
};

export default function FirebaseActionPage() {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const mode = query.get('mode');
  const oobCode = query.get('oobCode');

  switch (mode) {
    case ActionMode.RESET_PASSWORD:
      return <PasswordResetPage code={oobCode} />;
    case ActionMode.VERIFY_EMAIL:
      return <VerifyEmailPage code={oobCode} />;
    default:
      return <NotFoundPage />;
  }
}
