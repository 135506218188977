import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  Radio,
  RadioGroup,
  Tag,
  TagCloseButton,
  TagIcon,
  TagLabel,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/core';
import { navigate, useParams } from '@reach/router';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { MdOpenInNew, MdPerson, MdVerifiedUser } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import DeleteConfirmation from '../components/DeleteConfirmation';
import InvitationEmailConfirmation from '../components/InvitationEmailConfirmation';
import LanguageInput from '../components/LanguageInput';
import Layout from '../components/Layout';
import Loading from '../components/Loading';
import PageTitle from '../components/PageTitle';
import SEO from '../components/SEO';
import { useFetchAllLanguages, useFetchProject, useUpdateProject } from '../store/project';

export default function CustomizeProjectPage() {
  const { projectId } = useParams();
  const pushToast = useToast();

  const userId = useSelector((state) => state.auth._id);
  const currentProject = useSelector((state) => state.project.currentProject);
  const projectOwnerId = currentProject?.project?.userId?._id;

  const [isLoading, setIsLoading] = React.useState(userId && projectOwnerId ? false : true);

  const languages = useSelector((state) => state.project.allLanguages);
  const { fetchAllLanguages } = useFetchAllLanguages();
  const { state: projectState, fetchProject } = useFetchProject();

  const { state: updateState, updateProject } = useUpdateProject();

  useEffectOnce(() => {
    fetchAllLanguages();
    fetchProject({ projectId });
  });

  React.useEffect(() => {
    // If not the owner to customize this page, redirect to the project.
    if (userId && projectOwnerId && userId !== projectOwnerId) {
      navigate(`/`, {
        replace: true,
      });
    }

    if (userId && projectOwnerId && userId === projectOwnerId) {
      setIsLoading(false);
    }
  }, [userId, projectOwnerId]);

  const onEmailDelete = (form, values, email) => () => {
    form.change(
      'memberEmails',
      values.memberEmails.filter((member) => member !== email)
    );
  };

  const onEmailPressEnter = (form, values, input) => (ev) => {
    const enterKey = 13;

    if (ev.which === enterKey && values.memberEmail.trim().length === 0) {
      // No doing this is causing issues with the input field. Why?
      ev.preventDefault();
      return;
    }

    if (ev.which === enterKey) {
      // No doing this is causing issues with the input field. Why?
      ev.preventDefault();

      form.change('memberEmails', [
        ...new Set([...values.memberEmails, values.memberEmail.trim()]),
      ]);
      input.onChange('');
    }
  };

  const onEmailAdd = (form, values) => () => {
    if (values.memberEmail.trim().length !== 0) {
      form.change('memberEmails', [
        ...new Set([...values.memberEmails, values.memberEmail.trim()]),
      ]);
      form.change('memberEmail', '');
    }
  };

  const memberEmails = [
    ...(currentProject?.project?.members?.map((m) => m.email) ?? []),
    ...(currentProject?.project?.unregisteredMembers ?? []),
  ];

  const memberLevel = memberEmails.reduce((acc, cur) => {
    const isUnregistered = (currentProject?.project?.unregisteredMembers ?? []).includes(cur);
    acc[cur] = !isUnregistered;
    return acc;
  }, {});

  const project = currentProject?.project;

  return !isLoading ? (
    <Layout>
      <FormattedMessage id="CUSTOMIZE_N" values={{ name: project?.name ?? '' }}>
        {(value) => <SEO title={value} />}
      </FormattedMessage>

      <PageTitle
        title={<FormattedMessage id="CUSTOMIZE_N" values={{ name: project?.name ?? '' }} />}
        subtitle={
          project?.repoUrl ? (
            <Link href={project?.repoUrl} target="_blank">
              {project?.repoUrl} <Box as={MdOpenInNew} display="inline" />
            </Link>
          ) : (
            ''
          )
        }
        backTo={`/projects/${projectId}`}
      />

      <Form
        initialValues={{
          projectName: project?.name ?? '',
          translationsPath: project?.translationsPath ?? '',
          memberEmails,
          memberEmail: '',
          indentationKind: project?.indentationKind ?? 'SPACE',
          exportEmptyFields: project?.exportEmptyFields ? 'true' : 'false',
          ...languages.reduce((acc, cur) => {
            acc[`lang_${cur}`] = project?.supportedLanguages?.includes(cur) ?? false;
            return acc;
          }, {}),
        }}
        subscription={{ submitting: true }}
        onSubmit={async (value) => {
          const supportedLanguages = languages.filter((l) => !!value[`lang_${l}`]);
          await updateProject({
            projectId,
            projectName: value.projectName,
            translationsPath: value.translationsPath,
            supportedLanguages,
            memberEmails: value.memberEmails,
            indentationKind: value.indentationKind,
            exportEmptyFields: value.exportEmptyFields === 'true' ? true : false,
          });

          pushToast({
            description: 'Successfully saved the project settings.',
            status: 'success',
          });
        }}
        keepDirtyOnReinitialize
      >
        {({ handleSubmit, submitting }) => (
          <Box mt={4} mb={6} as="form" px={4}>
            <Field
              name="projectName"
              validate={(value) => (value ? null : 'Project name is required.')}
              render={({ input, meta }) => (
                <FormControl isInvalid={!projectState.loading && typeof meta.error === 'string'}>
                  <FormLabel htmlFor="projectName">
                    <FormattedMessage id="PROJECT_NAME" />
                  </FormLabel>
                  <Input {...input} id="projectName" />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            />

            <Field
              name="translationsPath"
              validate={(value) => (value ? null : 'Translations path is required.')}
              render={({ input, meta }) => (
                <FormControl
                  mt={4}
                  isInvalid={!projectState.loading && typeof meta.error === 'string'}
                >
                  <FormLabel htmlFor="translationsPath">
                    <FormattedMessage id="TRANSLATION_PATH" />
                  </FormLabel>
                  <Input {...input} id="translationsPath" placeholder="path/to/translations" />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>

                  <FormHelperText>
                    <FormattedMessage id="TRANSLATION_PATH_HELPER" />
                  </FormHelperText>
                </FormControl>
              )}
            />

            <FormControl mt={4}>
              <FormLabel htmlFor="projectName">
                <FormattedMessage id="PROJECT_MEMBERS" />
              </FormLabel>

              <Box border="1px solid #eaeaea" pb={2} px={2}>
                <FormSpy subscription={{ values: true }}>
                  {({ values, form }) => (
                    <>
                      <Box mb={values.memberEmails.length > 0 ? 2 : 0}>
                        {values.memberEmails.map((em) => (
                          <Box key={em} display="inline-block" mr={2} mt={2}>
                            <Tag variantColor={memberLevel[em] ? 'blue' : 'yellow'}>
                              <TagIcon>
                                {memberLevel[em] ? <MdVerifiedUser /> : <MdPerson />}
                              </TagIcon>

                              <TagLabel>
                                {memberLevel[em] ? (
                                  em
                                ) : (
                                  <FormattedMessage id="NOT_YET_REGISTERED">
                                    {(value) => (
                                      <Tooltip label={value} placement="right-end">
                                        {em}
                                      </Tooltip>
                                    )}
                                  </FormattedMessage>
                                )}
                              </TagLabel>
                              {memberLevel[em] === false && (
                                <InvitationEmailConfirmation email={em} projectId={projectId} />
                              )}
                              <TagCloseButton onClick={onEmailDelete(form, values, em)} />
                            </Tag>
                          </Box>
                        ))}
                      </Box>

                      <Box display="flex" mt={2}>
                        <Field
                          name="memberEmail"
                          render={({ input }) => (
                            <Input
                              {...input}
                              placeholder="email@example.com"
                              onKeyPress={onEmailPressEnter(form, values, input)}
                            />
                          )}
                        />

                        <Button ml={2} onClick={onEmailAdd(form, values)}>
                          <FormattedMessage id="ADD" />
                        </Button>
                      </Box>
                    </>
                  )}
                </FormSpy>
              </Box>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>
                <FormattedMessage id="SUPPORTED_LANGUAGES" />
              </FormLabel>
              <LanguageInput namePrefix="lang_" />
              <FormHelperText>
                <FormattedMessage id="SUPPORTED_LANGUAGES_HELPER" />
              </FormHelperText>
            </FormControl>

            {project.sourceType === 'JSON' && (
              <FormControl mt={4}>
                <FormLabel htmlFor="indentationKind">
                  <FormattedMessage id="INDENTATION_KIND" />
                </FormLabel>
                <Field
                  name="indentationKind"
                  render={({ input }) => (
                    <RadioGroup id="indentationKind" isInline {...input}>
                      <Radio value="SPACE">
                        <FormattedMessage id="SPACE" />
                      </Radio>
                      <Radio value="TAB">
                        <FormattedMessage id="TABS" />
                      </Radio>
                    </RadioGroup>
                  )}
                />
                <FormHelperText>
                  <FormattedMessage id="INDENTATION_KIND_HELPER" />
                </FormHelperText>
              </FormControl>
            )}

            <FormControl mt={4}>
              <FormLabel htmlFor="exportEmptyFields">
                <FormattedMessage id="OMIT_EMPTY_TRANSLATIONS" />
              </FormLabel>
              <Field
                name="exportEmptyFields"
                render={({ input }) => (
                  <RadioGroup id="exportEmptyFields" isInline {...input}>
                    <Radio value="false">
                      <FormattedMessage id="OMIT" />
                    </Radio>
                    <Radio value="true">
                      <FormattedMessage id="INCLUDE" />
                    </Radio>
                  </RadioGroup>
                )}
              />
              <FormHelperText>
                <FormattedMessage id="OMIT_EMPTY_TRANSLATIONS_HELPER" />
              </FormHelperText>
            </FormControl>

            {!updateState.loading && updateState.error && (
              <FormControl isInvalid>
                <FormErrorMessage>
                  {updateState.error?.response?.data?.message ?? (
                    <FormattedMessage id="SOMETHING_BAD_HAPPENED" />
                  )}
                </FormErrorMessage>
              </FormControl>
            )}

            <Button mt={5} onClick={handleSubmit} variantColor="green" isLoading={submitting}>
              <FormattedMessage id="UPDATE" />
            </Button>
          </Box>
        )}
      </Form>

      <Box px={4} mt={12} mb={16}>
        <Heading fontSize="lg">
          <FormattedMessage id="DANGER_AREA" />
        </Heading>

        <Text mt={4}>
          <FormattedMessage id="CHANGE_GITHUB_REPO_INFO" />
        </Text>
        <Button mt={2} onClick={() => navigate(`/projects/${projectId}/change-repo`)}>
          <FormattedMessage id="CHANGE_GITHUB_REPO" />
        </Button>

        <Text mt={6}>
          <FormattedMessage id="DELETE_PROJECT_INFO" />
        </Text>
        <DeleteConfirmation mt={2} />
      </Box>
    </Layout>
  ) : (
    <Loading />
  );
}
