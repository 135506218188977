import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import languages from '../assets/languages.svg';
import { useResetPassword } from '../store/auth';
import * as v from '../validations';
import Image from './Image';

export default function PasswordReset({ code }) {
  const pushToast = useToast();
  const { resetState, resetPassword } = useResetPassword();

  return (
    <Flex justifyContent="center">
      <Box
        width={[400, 440, null, 360, 440]}
        shadow="lg"
        px={8}
        py={12}
        rounded="md"
        zIndex={100}
        bg="white"
      >
        <Heading as="h3" size="xl" color="gray.800" mb={8}>
          <FormattedMessage id="RESET_PASSWORD" />
        </Heading>

        <Form
          initialValues={{ password: '' }}
          onSubmit={async (state) => {
            const res = await resetPassword({ code, password: state.password });
            if (res.ok) {
              pushToast({
                description: 'Successfully updated the password.',
                status: 'success',
              });
              navigate('/login');
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="password"
                validate={v.validatePassword}
                render={({ input, meta }) => (
                  <FormControl mt={4} isInvalid={meta.touched && meta.error}>
                    <FormLabel htmlFor="password" color="gray.500" fontWeight="bold">
                      <FormattedMessage id="PASSWORD" />
                    </FormLabel>

                    <FormattedMessage id="ENTER_NEW_PASSWORD">
                      {(value) => (
                        <Input
                          id="password"
                          type="password"
                          size="lg"
                          placeholder={value}
                          borderTop="none"
                          borderLeft="none"
                          borderRight="none"
                          borderRadius={0}
                          _focus={{
                            boxShadow: 'none',
                            borderColor: 'green.500',
                          }}
                          {...input}
                        />
                      )}
                    </FormattedMessage>

                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              {!resetState.loading && resetState.error && (
                <Text color="red.500" mt={2}>
                  {resetState.error?.description ?? resetState.error?.message ?? (
                    <FormattedMessage id="SOMETHING_BAD_HAPPENED" />
                  )}
                </Text>
              )}

              <Button
                type="submit"
                isLoading={resetState.loading}
                mt={6}
                width="100%"
                variantColor="green"
              >
                <FormattedMessage id="RESET_PASSWORD" />
              </Button>
            </form>
          )}
        </Form>
      </Box>

      <Box position="absolute" bottom={12} left={12} display={['none', null, null, 'block']}>
        <Box width={300}>
          <Image src={languages} />
        </Box>
      </Box>
    </Flex>
  );
}
