import { Box, Divider, Flex, Heading, Skeleton, Text } from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import help from '../assets/help.svg';
import newProject from '../assets/new_project.svg';
import ActionCard from '../components/ActionCard';
import Layout from '../components/Layout';
import ProjectCard from '../components/ProjectCard';
import SEO from '../components/SEO';
import { useFetchAllProjects } from '../store/project';

export default function HomePage() {
  const userId = useSelector((state) => state.auth._id);
  const allProjects = useSelector((state) => state.project.allProjects);
  const { state, fetchAllProjects } = useFetchAllProjects();

  useEffectOnce(() => {
    fetchAllProjects();
  });

  return (
    <Layout>
      <SEO title="Home" />

      <Heading as="h4" size="lg" mt={6} textAlign="center">
        <FormattedMessage id="WELCOME" />
      </Heading>
      {!state.loading && allProjects.length === 0 && (
        <Text color="gray.500" textAlign="center" paddingX={8}>
          <FormattedMessage id="EMPTY_PROJECT" />
        </Text>
      )}
      <Divider />

      <Flex mt={8} justifyContent="center">
        <FormattedMessage id="ADD_PROJECT">
          {(value) => <ActionCard imageSrc={newProject} text={value} mx={4} to="/add-project" />}
        </FormattedMessage>
        <FormattedMessage id="HELP">
          {(value) => <ActionCard imageSrc={help} text={value} mx={4} to="/help" />}
        </FormattedMessage>
      </Flex>

      <Heading as="h5" size="sm" color="gray.600" mt={12} paddingX={8}>
        <FormattedMessage id="ALL_PROJECTS" />
      </Heading>
      <Divider />

      {!state.loading && allProjects.length === 0 && (
        <Box mx={8} mt={4}>
          <Text color="gray.500">
            <FormattedMessage id="DO_NOT_HAVE_PROJECTS" />
          </Text>
        </Box>
      )}

      {state.loading &&
        Array(5)
          .fill(0)
          .map((_, index) => <Skeleton key={index} height={20} my={4} mx={4} />)}

      {!state.loading &&
        allProjects.map((p) => (
          <ProjectCard
            key={p._id}
            name={p.name}
            createdBy={p.userId.name}
            githubRepo={p.repoFullName}
            supportedLanguages={p.supportedLanguages}
            isOwner={p.userId._id === userId}
            to={`/projects/${p._id}`}
          />
        ))}
    </Layout>
  );
}
