import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Select,
  useToast,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useFetchGithubRepoBranches } from '../store/github';
import { useUpdateGithubRepo } from '../store/project';

export default function RepoUpdateConfirmation({ isOpen, onClose, github }) {
  const pushToast = useToast();
  const currentProject = useSelector((state) => state.project.currentProject);
  const projectId = currentProject?.project?._id;

  const cancelRef = React.useRef();
  const { state, updateGithubRepo } = useUpdateGithubRepo();

  const { fetchGithubRepoBranches, state: branches } = useFetchGithubRepoBranches();
  const [selectedBranch, setSelectedBranch] = React.useState(github?.defaultBranch);
  React.useEffect(() => {
    if (github?.name) {
      fetchGithubRepoBranches({ repoOwner: github.owner, repoName: github.name });
    }
  }, [fetchGithubRepoBranches, github]);

  React.useEffect(() => {
    setSelectedBranch(github?.defaultBranch ?? '');
  }, [github]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={state.loading ? null : onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" pt={4} pb={2}>
        <AlertDialogBody>
          <FormattedMessage id="CHANGE_GITHUB_REPO_CONFIRMATION" />

          <Select
            value={selectedBranch}
            onChange={(ev) => setSelectedBranch(ev.target.value)}
            mt={3}
          >
            {(branches.value ?? []).map((it) => (
              <option key={it} value={it}>
                {it}
              </option>
            ))}
          </Select>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} isDisabled={state.loading}>
            Cancel
          </Button>
          <Button
            variantColor="red"
            isLoading={state.loading}
            onClick={async () => {
              const res = await updateGithubRepo({
                projectId,
                github: { ...github, branch: selectedBranch },
              });
              onClose();

              if (res.ok) {
                pushToast({
                  description: 'Successfully update the github repo of the project.',
                  status: 'success',
                });
                navigate(`/projects/${projectId}/customize`);
                return;
              }

              pushToast({
                description: 'Failed to update the github repo.',
                status: 'error',
              });
            }}
            ml={3}
          >
            <FormattedMessage id="OK" />
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
