import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  FormControl,
  FormLabel,
  Select,
} from '@chakra-ui/core';
import { DisplayNames } from '@formatjs/intl-displaynames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import loc, { appLangs } from '../store/locale';

export default function ChangeLanguage({ isOpen, onClose }) {
  const cancelRef = React.useRef();
  const currentLang = useSelector((state) => state.locale.locale);
  const [lang, setLang] = React.useState(currentLang ?? 'en');
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" pt={4} pb={2}>
        <AlertDialogBody>
          <FormControl>
            <FormLabel>
              <FormattedMessage id="APP_LANGUAGE" />
            </FormLabel>
            <Select value={lang} onChange={(e) => setLang(e.target.value)}>
              {appLangs.map((lang) => (
                <option key={lang} value={lang === 'default' ? 'en' : lang}>
                  {new DisplayNames(intl.locale).of(lang === 'default' ? 'en' : lang)}
                </option>
              ))}
            </Select>
          </FormControl>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button
            variantColor="red"
            onClick={() => {
              dispatch(loc.actions.setLocale(lang));
              onClose();
            }}
            ml={3}
          >
            <FormattedMessage id="UPDATE" />
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
