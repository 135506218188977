import { Box, Flex } from '@chakra-ui/core';
import React from 'react';
import Navigation from './Navigation';

export default function Layout({ navigation, children }) {
  return (
    <>
      {navigation ?? <Navigation />}
      <Flex justifyContent="center" minHeight="calc(100vh - 64px)" bg="gray.100">
        {/* These boxes on both the sides of the main content are used so that any shadow within the content
        does not overflow outside its boundaries and are hidden. */}
        <Box flexGrow={1} bg="gray.100" zIndex={400}></Box>
        <Box maxWidth={1280} width="100%" bg="white" position="relative" flex="1280px">
          {children}
        </Box>
        <Box flexGrow={1} bg="gray.100" zIndex={400}></Box>
      </Flex>
    </>
  );
}
