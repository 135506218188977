import { Box, Grid } from '@chakra-ui/core';
import styled from '@emotion/styled';

export const Table = styled(Box)`
  padding-top: ${(props) => props.theme.space[1]};
  padding-bottom: ${(props) => props.theme.space[1]};
  width: 100%;
`;

export const TableHead = styled(Grid)`
  font-weight: ${(props) => props.theme.fontWeights['semibold']};
  border-bottom: ${(props) => props.theme.borders['1px']};
  border-bottom-color: ${(props) => props.theme.colors.gray[200]};
  padding-left: ${(props) => props.theme.space[4]};
  padding-right: ${(props) => props.theme.space[4]};
  padding-top: ${(props) => props.theme.space[3]};
  padding-bottom: ${(props) => props.theme.space[3]};
`;

export const TableRow = styled(Grid)`
  border-top: ${(props) => (props.isFirst ? 'none' : props.theme.borders['1px'])};
  border-top-color: ${(props) => props.theme.colors.gray[200]};
  border-bottom: ${(props) => (props.isLast ? props.theme.borders['1px'] : 'none')};
  border-bottom-color: ${(props) => props.theme.colors.gray[200]};
  padding-left: ${(props) => props.theme.space[4]};
  padding-right: ${(props) => props.theme.space[4]};
  padding-top: ${(props) => props.theme.space[3]};
  padding-bottom: ${(props) => props.theme.space[3]};
`;
