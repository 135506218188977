import { Box, Button, Flex, Heading, Text } from '@chakra-ui/core';
import { Link } from '@reach/router';
import React from 'react';
import { MdArrowBack } from 'react-icons/md';
import { useWindowScroll } from 'react-use';

export default function PageTitle({ title, subtitle, prepend, append, bottom, boxShadow, backTo }) {
  const { y } = useWindowScroll();

  const titlePart = (
    <>
      {/* On smaller screens if text is longer than 30 chars then decrease the font size. */}
      <Heading as="h3" fontSize={[title?.length > 30 ? 16 : 18, null, 18]}>
        {title}
      </Heading>
      <Text color="gray.500">{subtitle}</Text>{' '}
    </>
  );

  return (
    <Box
      backgroundColor="white"
      boxShadow={y !== 0 ? boxShadow ?? '0 2px 4px rgba(0,0,0,.1)' : null}
      position="sticky"
      top="4rem"
      zIndex={500}
    >
      <Flex alignItems="center" justifyContent="space-between" p={4} width="100%">
        <Box maxWidth="90%" overflow="hidden">
          <Flex alignItems="center">
            <Box>
              <Button as={Link} to={backTo ?? '#'}>
                <Box as={MdArrowBack} />
              </Button>
            </Box>
            <Box ml={4}>
              {!prepend && titlePart}

              {prepend && (
                <>
                  <Box display={['block', null, null, 'none']}>{titlePart}</Box>
                  <Box display={['none', null, null, 'block']}>{prepend}</Box>
                </>
              )}
            </Box>
          </Flex>
        </Box>

        {append && <Box>{append}</Box>}
      </Flex>

      {bottom}
    </Box>
  );
}
