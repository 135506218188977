import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Link,
  Radio,
  RadioGroup,
  Select,
  useToast,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { MdOpenInNew } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import axios from '../axios';
import LanguageInput from '../components/LanguageInput';
import Layout from '../components/Layout';
import NewProjectProgress from '../components/NewProjectProgress';
import PageTitle from '../components/PageTitle';
import SEO from '../components/SEO';
import { useFetchGithubRepoBranches } from '../store/github';
import { useFetchAllLanguages } from '../store/project';

export default function ConfigureProjectPage() {
  const newProject = useSelector((state) => state.github.newProject);
  const languages = useSelector((state) => state.project.allLanguages);
  const { fetchAllLanguages } = useFetchAllLanguages();
  const { fetchGithubRepoBranches, state: branches } = useFetchGithubRepoBranches();
  const pushToast = useToast();

  useEffectOnce(() => {
    if (!newProject.fullName) {
      navigate('/add-project');
      return;
    }

    fetchAllLanguages();
    fetchGithubRepoBranches({ repoOwner: newProject.owner, repoName: newProject.name });
  });

  return (
    <Layout>
      <SEO title={`Configure ${newProject.fullName}`} />

      <PageTitle
        title={<FormattedMessage id="CONFIGURE_N_PROJECT" values={{ name: newProject.fullName }} />}
        subtitle={
          <Link href={newProject.url} target="_blank">
            {newProject.url} <Box as={MdOpenInNew} display="inline" />
          </Link>
        }
        backTo="/add-project"
      />

      <Form
        initialValues={{
          projectName: newProject.name,
          branch: newProject.defaultBranch,
          translationsPath: '',
          sourceType: 'JSON',
          translationFilePrefix: 'i18n',
          ...languages.reduce((acc, cur) => {
            acc[`lang_${cur}`] = false;
            return acc;
          }, {}),
        }}
        subscription={{ submitting: true }}
        onSubmit={async (value) => {
          const supportedLanguages = languages.filter((l) => !!value[`lang_${l}`]);
          try {
            const res = await axios.post('/api/projects', {
              cloneUrl: newProject.cloneUrl,
              name: newProject.name,
              fullName: newProject.fullName,
              url: newProject.url,
              installationId: newProject.installationId,
              branch: value.branch,
              projectName: value.projectName,
              translationsPath: value.translationsPath,
              supportedLanguages,
              sourceType: value.sourceType,
              translationFilePrefix: value.translationFilePrefix,
            });
            navigate(`/projects/${res.data.project._id}`);
          } catch (error) {
            pushToast({
              description: error.response?.data?.message ?? 'Something bad happened',
              status: 'error',
            });
          }
        }}
      >
        {({ handleSubmit, submitting }) => (
          <Box as="form" onSubmit={handleSubmit} mt={4} mb={12} px={[4, null, 8]}>
            <Box display="grid" gridTemplateColumns="repeat(2, 1fr)">
              <FormControl mr={2}>
                <FormLabel htmlFor="repoName">
                  <FormattedMessage id="GITHUB_REPO" />
                </FormLabel>
                <Input id="repoName" value={newProject.fullName} disabled />
              </FormControl>

              <Field
                name="branch"
                validate={(value) => (value ? null : 'Project name is required.')}
                render={({ input, meta }) => (
                  <FormControl ml={2} isInvalid={meta.touched && typeof meta.error === 'string'}>
                    <FormLabel htmlFor="branch">
                      <FormattedMessage id="BRANCH" />
                    </FormLabel>
                    <Select id="branch" {...input}>
                      {(branches.value ?? []).map((it) => (
                        <option key={it} value={it}>
                          {it}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{meta.error}</FormErrorMessage>
                  </FormControl>
                )}
              />
            </Box>

            <Field
              name="projectName"
              validate={(value) => (value ? null : 'Project name is required.')}
              render={({ input, meta }) => (
                <FormControl isInvalid={meta.touched && typeof meta.error === 'string'} mt={4}>
                  <FormLabel htmlFor="projectName">
                    <FormattedMessage id="NEW_PROJECT_NAME" />
                  </FormLabel>
                  <Input {...input} id="projectName" />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                </FormControl>
              )}
            />

            <Field
              name="translationsPath"
              validate={(value) => (value ? null : 'Translations path is required.')}
              render={({ input, meta }) => (
                <FormControl mt={4} isInvalid={meta.touched && typeof meta.error === 'string'}>
                  <FormLabel htmlFor="translationsPath">
                    <FormattedMessage id="TRANSLATION_PATH" />
                  </FormLabel>
                  <Input {...input} id="translationsPath" placeholder="path/to/translations" />
                  <FormErrorMessage>{meta.error}</FormErrorMessage>

                  <FormHelperText>
                    <FormattedMessage id="TRANSLATION_PATH_HELPER" />
                  </FormHelperText>
                </FormControl>
              )}
            />

            <Field
              name="sourceType"
              validate={(value) => (value ? null : 'Source type is required.')}
              render={({ input, meta }) => (
                <FormControl mt={4} isInvalid={meta.touched && typeof meta.error === 'string'}>
                  <FormLabel htmlFor="sourceType">
                    <FormattedMessage id="SOURCE_TYPE" />
                  </FormLabel>
                  <RadioGroup id="sourceType" isInline {...input}>
                    <Radio value="JSON">
                      <FormattedMessage id="JSON" />
                    </Radio>
                    <Radio value="PROPERTIES">
                      <FormattedMessage id="PROPERTIES" />
                    </Radio>
                  </RadioGroup>
                  <FormErrorMessage>{meta.error}</FormErrorMessage>
                  <FormHelperText>
                    <FormattedMessage id="SOURCE_TYPE_HELPER" />
                  </FormHelperText>
                </FormControl>
              )}
            />

            <FormSpy subscription={{ values: true }}>
              {({ values }) => (
                <Field
                  name="translationFilePrefix"
                  validate={(value) => (value ? null : 'Translation file prefix is required.')}
                  render={({ input, meta }) => (
                    <FormControl mt={4} isInvalid={meta.touched && typeof meta.error === 'string'}>
                      <FormLabel htmlFor="translationFilePrefix">
                        <FormattedMessage id="TRANSLATION_FILE_PREFIX" />
                      </FormLabel>
                      <RadioGroup id="translationFilePrefix" isInline {...input}>
                        <Radio value="i18n">i18n</Radio>
                        <Radio value="">
                          <FormattedMessage id="CUSTOM" />
                        </Radio>
                      </RadioGroup>
                      {input.value !== 'i18n' && (
                        <Input
                          mt={3}
                          placeholder="Prefix such as i18n, translations, etc"
                          {...input}
                        />
                      )}
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                      <FormHelperText>
                        <FormattedMessage
                          id="TRANSLATION_FILE_PREFIX_HELPER"
                          values={{
                            prefix: input.value || 'i18n',
                            ext: values.sourceType.toLowerCase(),
                          }}
                        />
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              )}
            </FormSpy>

            <FormControl mt={4}>
              <FormLabel>
                <FormattedMessage id="SUPPORTED_LANGUAGES" />
              </FormLabel>
              <LanguageInput namePrefix="lang_" />
              <FormHelperText>
                <FormattedMessage id="SUPPORTED_LANGUAGES_HELPER" />
              </FormHelperText>
            </FormControl>

            <Box mt={3}>
              <Button isLoading={submitting} type="submit" variantColor="green">
                <FormattedMessage id="NEXT" />
              </Button>
            </Box>

            <NewProjectProgress isLoading={submitting} />
          </Box>
        )}
      </Form>
    </Layout>
  );
}
