import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/core';
import { Link as Lnk, navigate } from '@reach/router';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import languages from '../assets/languages.svg';
import { useSendResetPassword } from '../store/auth';
import * as v from '../validations';
import Image from './Image';

export default function ForgotPassword() {
  const { state, sendResetPassword } = useSendResetPassword();
  const pushToast = useToast();

  return (
    <Flex justifyContent="center">
      <Box
        width={[400, 440, null, 360, 440]}
        shadow="lg"
        px={8}
        py={12}
        rounded="md"
        zIndex={100}
        bg="white"
      >
        <Heading as="h3" size="xl" color="gray.800">
          <FormattedMessage id="FORGOT_PASSWORD" />
        </Heading>

        <Form
          initialValues={{ email: '' }}
          onSubmit={async (state) => {
            const res = await sendResetPassword(state);
            if (res.ok) {
              pushToast({
                description: 'Sent a password reset link to your email.',
                status: 'success',
              });

              navigate('/login');
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                validate={v.validateEmail}
                render={({ input, meta }) => (
                  <FormControl mt={12} isInvalid={meta.touched && meta.error}>
                    <FormLabel htmlFor="email" color="gray.500" fontWeight="bold">
                      <FormattedMessage id="EMAIL" />
                    </FormLabel>
                    <Input
                      id="email"
                      size="lg"
                      placeholder="you@example.com"
                      borderTop="none"
                      borderLeft="none"
                      borderRight="none"
                      borderRadius={0}
                      _focus={{
                        boxShadow: 'none',
                        borderColor: 'green.500',
                      }}
                      {...input}
                    />

                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              {!state.loading && state.error && (
                <Text color="red.500" mt={2}>
                  {state.error?.description ?? <FormattedMessage id="SOMETHING_BAD_HAPPENED" />}
                </Text>
              )}

              <Button
                type="submit"
                isLoading={state.loading}
                mt={6}
                width="100%"
                variantColor="green"
              >
                <FormattedMessage id="RESET_PASSWORD" />
              </Button>
              <FormHelperText>
                <FormattedMessage id="RESET_PASSWORD_EMAIL_HELPER" />
              </FormHelperText>
            </form>
          )}
        </Form>

        <Box mt={6}>
          <Text as="span">
            <FormattedMessage id="REMEMBER_PASSWORD" />
          </Text>
          <Link as={Lnk} to="/login" display="inline" ml={2} fontWeight="bold" color="green.500">
            <FormattedMessage id="LOGIN" />
          </Link>
        </Box>
      </Box>

      <Box position="absolute" bottom={12} left={12} display={['none', null, null, 'block']}>
        <Box width={300}>
          <Image src={languages} />
        </Box>
      </Box>
    </Flex>
  );
}
