import { Box, Button, Heading, Text, useDisclosure } from '@chakra-ui/core';
import React from 'react';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';
import { FormattedMessage } from 'react-intl';

export default function DeleteAccount() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box mx={4} mt={8}>
        <Heading as="h5" fontSize="md" mb={4}>
          <FormattedMessage id="DELETE_YOUR_ACCOUNT" />
        </Heading>
        <Button variantColor="red" onClick={onOpen}>
          <FormattedMessage id="DELETE_ACCOUNT" />
        </Button>
        <Text mt={1} color="gray.500">
          <FormattedMessage id="DELETE_ACCOUNT_MESSAGE" />
        </Text>
      </Box>

      <DeleteAccountConfirmation isOpen={isOpen} onClose={onClose} />
    </>
  );
}
