import { Box, Text, useToast } from '@chakra-ui/core';
import { navigate, useLocation } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import axios from '../axios';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import SEO from '../components/SEO';
import { useFetchAuth } from '../store/auth';

export default function GithubAuthorizePage() {
  const { search } = useLocation();
  const { fetchAuth } = useFetchAuth();
  const pushToast = useToast();

  React.useEffect(
    () => {
      const code = new URLSearchParams(search).get('code');
      if (!code) {
        // There is no code.
        navigate('/add-project');
      }

      axios
        .post(`/api/github/access-token`, { code })
        .then(async (res) => {
          // Store the token in the store and local storage.
          await fetchAuth();
          navigate('/add-project');
        })
        .catch((err) => {
          pushToast({
            description: err.response?.data?.message ?? 'Something bad happened.',
            status: 'error',
          });

          navigate('/add-project');
        });
    },
    // Only invoke once on load.
    // eslint-disable-next-line
    []
  );

  return (
    <Layout>
      <FormattedMessage id="ADD_PROJECT">{(value) => <SEO title={value} />}</FormattedMessage>

      <PageTitle
        title={<FormattedMessage id="ADD_PROJECT" />}
        subtitle={<FormattedMessage id="ADD_PROJECT_SUBTITLE" />}
        backTo="/"
      />

      <Box mt={4} px={8}>
        <Text>
          <FormattedMessage id="JUST_WAIT_LOGGING" />
        </Text>
      </Box>
    </Layout>
  );
}
