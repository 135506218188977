import { DisplayNames } from '@formatjs/intl-displaynames';
import React from 'react';
import ReactDOM from 'react-dom';
import './firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';

DisplayNames.__addLocaleData(require('@formatjs/intl-displaynames/dist/locale-data/en.json'));
DisplayNames.__addLocaleData(require('@formatjs/intl-displaynames/dist/locale-data/de.json'));

const rootEl = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootEl
);

// Enable hot reload without the need for refresh.
if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;

    ReactDOM.render(
      <React.StrictMode>
        <NextApp />
      </React.StrictMode>,
      rootEl
    );
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
