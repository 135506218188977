import { Box, Button, Flex, IconButton, Text, Textarea, useToast } from '@chakra-ui/core';
import { DisplayNames } from '@formatjs/intl-displaynames';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { MdTranslate, MdInfoOutline } from 'react-icons/md';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useWindowScroll, useWindowSize } from 'react-use';
import CommentRow from '../components/CommentRow';
import { Table, TableHead, TableRow } from '../components/Table';
import { doesLangRequireInputTool } from '../store/locale';
import { translatableLanguages, useTranslateText } from '../store/translate';

const COMMENT_REGEX = /^%COMMENT\+\d+%$/;

function useIsScrolledToBottom() {
  const { y } = useWindowScroll();
  const { height: windowHeight } = useWindowSize();
  const height = Math.max(
    document.body?.scrollHeight ?? 0,
    document.body?.offsetHeight ?? 0,
    document.html?.clientHeight ?? 0,
    document.html?.scrollHeight ?? 0,
    document.html?.offsetHeight ?? 0
  );

  return {
    y,
    isScrolledToBottom: y >= height - windowHeight,
  };
}

function FloatingFooter({ children, isScrolledToBottom }) {
  return (
    <Flex
      justifyContent="flex-end"
      alignItems="center"
      backgroundColor="white"
      position="sticky"
      bottom={0}
      px={4}
      pt={2}
      pb={3}
      boxShadow={isScrolledToBottom ? null : '0 -1px 4px rgba(0,0,0,.2)'}
    >
      {children}
    </Flex>
  );
}

function TranslationRow({
  isFirst,
  isLast,
  recordKey,
  currentProject,
  canAutoTranslate,
  lang,
  ongoingTranslations,
  setOngoingTranslations,
  isRefreshing,
  translateText,
  commentKeys = [],
}) {
  const pushToast = useToast();
  const intl = useIntl();

  const [commentVisible, setCommentVisible] = React.useState(false);

  return (
    <TableRow
      key={recordKey}
      isFirst={isFirst}
      isLast={isLast}
      gridTemplateColumns={[null, null, canAutoTranslate ? '5fr 3fr' : '4.5fr 3fr']}
      gap={2}
    >
      <Box>
        <Box
          display={['flex', null, 'grid']}
          gridTemplateColumns={[null, null, canAutoTranslate ? '1.5fr 3.5fr' : '1.5fr 3fr']}
          gap={2}
          flexDirection="column"
        >
          <Flex justifyContent="space-between" alignItems="flex-start">
            <Box>
              <Text fontWeight="bold" fontSize="sm" display={[null, null, 'none']}>
                <FormattedMessage id="KEY" />
              </Text>
              <Text overflowWrap="anywhere">{recordKey} </Text>
            </Box>

            <IconButton
              display={['flex', null, 'none']}
              icon={MdInfoOutline}
              variant="outline"
              width={6}
              minWidth={6}
              height={6}
              fontSize={18}
              color="green.400"
              onClick={() => setCommentVisible((val) => !val)}
            />
          </Flex>

          <Box
            display={['flex', null, 'grid']}
            gridTemplateColumns={canAutoTranslate ? '3fr 0.5fr' : '3fr'}
            justifyContent="space-between"
            alignItems={['center', null, 'flex-start']}
            mt={[2, null, 0]}
          >
            <Box>
              <Text fontWeight="bold" fontSize="sm" display={[null, null, 'none']}>
                <FormattedMessage id="SOURCE_TEXT" />
              </Text>
              <Text
                color={!currentProject.default.json[recordKey] ? 'gray.500' : null}
                fontStyle={!currentProject.default.json[recordKey] ? 'italic' : null}
                fontSize={!currentProject.default.json[recordKey] ? 'sm' : null}
              >
                {currentProject.default.json[recordKey] || 'No Text'}
              </Text>
            </Box>

            {canAutoTranslate && currentProject.default.json[recordKey] && (
              <Box textAlign="center">
                <FormSpy subscription={{}}>
                  {({ form }) => (
                    <IconButton
                      icon={MdTranslate}
                      size="sm"
                      fontSize="md"
                      variantColor="blueGray"
                      isLoading={ongoingTranslations[recordKey]}
                      isDisabled={isRefreshing}
                      onClick={async () => {
                        setOngoingTranslations((tr) => ({ ...tr, [recordKey]: true }));
                        const res = await translateText({
                          text: currentProject.default.json[recordKey],
                          targetLang: lang,
                          projectId: currentProject.project._id,
                        });

                        if (res.ok) {
                          form.change(recordKey, res.translated);
                        } else {
                          pushToast({
                            description: 'Failed to translate the text.',
                            status: 'error',
                          });
                        }
                        setOngoingTranslations((tr) => ({ ...tr, [recordKey]: false }));
                      }}
                    />
                  )}
                </FormSpy>
              </Box>
            )}
          </Box>
        </Box>

        {commentKeys.length > 0 && (
          <>
            <IconButton
              display={['none', null, 'flex']}
              icon={MdInfoOutline}
              variant="outline"
              width={6}
              minWidth={6}
              height={6}
              fontSize={18}
              color="green.400"
              onClick={() => setCommentVisible((val) => !val)}
            />
            {commentVisible && (
              <Box bg="gray.100" mt={2} p={2} borderRadius="sm">
                <Text fontSize="sm" color="gray.500" mb={1}>
                  Comments:
                </Text>
                {commentKeys.map((cKey) => (
                  <CommentRow key={cKey} comment={currentProject?.default?.json?.[cKey]} />
                ))}
              </Box>
            )}
          </>
        )}
      </Box>

      <Box>
        <Text fontWeight="bold" fontSize="sm" display={[null, null, 'none']}>
          <FormattedMessage
            id="TRANSLATE_IN_LANGUAGE"
            values={{
              lang:
                currentProject?.translate?.lang || lang
                  ? new DisplayNames(intl.locale).of(currentProject.translate?.lang ?? lang)
                  : '',
            }}
          />
        </Text>
        <Field name={recordKey} render={({ input }) => <Textarea {...input} />} />
      </Box>
    </TableRow>
  );
}

function mergeCommentsWithTranslationItem(keys) {
  const final = [];
  let comments = [];

  keys.forEach((key) => {
    if (COMMENT_REGEX.test(key)) {
      comments.push(key);
      return;
    }

    final.push([key, comments]);
    comments = [];
  });

  return final;
}

export default function TranslationTable({ lang, isRefreshing, isTranslatedAll, handleSubmit }) {
  const intl = useIntl();
  const { y, isScrolledToBottom } = useIsScrolledToBottom();
  const currentProject = useSelector((state) => state.project.currentProject);
  const [ongoingTranslations, setOngoingTranslations] = React.useState({});
  const { translateText } = useTranslateText();

  const canAutoTranslate = translatableLanguages.includes(lang);
  const translationKeys = mergeCommentsWithTranslationItem(
    Object.keys(currentProject?.default?.json ?? {})
  );

  return (
    <Table position="relative">
      <TableHead
        display={['none', null, 'grid']}
        templateColumns={canAutoTranslate ? '1.5fr 3fr .5fr 3fr' : '1.5fr 3fr 3fr'}
        position="sticky"
        bg="white"
        top={doesLangRequireInputTool(lang) ? 170 : 155}
        zIndex={300}
        boxShadow={y > 0 ? '0 1px 4px rgba(0,0,0,.2)' : null}
      >
        <Text fontWeight="bold">
          <FormattedMessage id="KEY" />
        </Text>
        <Text fontWeight="bold">
          <FormattedMessage id="SOURCE_TEXT" />
        </Text>
        {canAutoTranslate && (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box as={MdTranslate} />
          </Box>
        )}
        <Text fontWeight="bold">
          <FormattedMessage
            id="TRANSLATE_IN_LANGUAGE"
            values={{
              lang:
                currentProject?.translate?.lang || lang
                  ? new DisplayNames(intl.locale).of(currentProject.translate?.lang ?? lang)
                  : '',
            }}
          />
        </Text>
      </TableHead>
      {currentProject?.default && (
        <Box as="form" onSubmit={handleSubmit}>
          {translationKeys.map(([k, commentKeys], index) => (
            <TranslationRow
              key={k}
              isFirst={index === 0}
              isLast={index === translationKeys.length - 1}
              recordKey={k}
              commentKeys={commentKeys}
              canAutoTranslate={canAutoTranslate}
              currentProject={currentProject}
              isRefreshing={isRefreshing}
              lang={lang}
              ongoingTranslations={ongoingTranslations}
              setOngoingTranslations={setOngoingTranslations}
              translateText={translateText}
            />
          ))}

          <FloatingFooter isScrolledToBottom={isScrolledToBottom}>
            <FormSpy subscription={{ dirty: true, submitting: true }}>
              {({ dirty, submitting }) => (
                <>
                  {(dirty || isTranslatedAll) && (
                    <Box textAlign="right" mr={6}>
                      <Text color="gray.700">There are unsaved changes.</Text>
                      <Text fontSize="sm" color="gray.500">
                        These won't be available in PRs and exports.
                      </Text>
                    </Box>
                  )}

                  <Button
                    type="submit"
                    variantColor="green"
                    isLoading={submitting}
                    isDisabled={isRefreshing}
                  >
                    <FormattedMessage id="SAVE" />
                  </Button>
                </>
              )}
            </FormSpy>
          </FloatingFooter>
        </Box>
      )}
    </Table>
  );
}
