import { Box, Flex } from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import logoDark from '../assets/logo_dark.png';
import logo from '../assets/logo_outline.png';
import AppInfo from '../components/AppInfo';
import Image from '../components/Image';
import Login from '../components/Login';
import SectionedLayout from '../components/SectionedLayout';
import SEO from '../components/SEO';

export default function LoginPage() {
  return (
    <>
      <FormattedMessage id="LOGIN">{(value) => <SEO title={value} />}</FormattedMessage>
      <SectionedLayout
        left={
          <>
            <Box zIndex={100}>
              <Image src={logo} height={12} display={['none', null, null, 'block']} />
            </Box>

            <Flex
              px={8}
              mt={[16, null, null, 4, -32]}
              mb={[16, null, null, 0]}
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <Login />
            </Flex>
          </>
        }
        right={
          <>
            <Image src={logoDark} mt={4} height={12} display={['block', null, null, 'none']} />
            <AppInfo />
          </>
        }
      />
    </>
  );
}
