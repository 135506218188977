import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function DiscardConfirmation({ isOpen, onClose, onOk }) {
  const cancelRef = React.useRef();

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" pt={4} pb={2}>
        <AlertDialogBody>
          <FormattedMessage id="PROJECT_CHANGES_DISCARD" />
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button variantColor="red" onClick={onOk} ml={3}>
            <FormattedMessage id="OK" />
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
