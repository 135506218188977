import { createSlice } from '@reduxjs/toolkit';
import en from '../intl/i18n.json';
import de from '../intl/i18n_de.json';
import ne from '../intl/i18n_ne.json';
import bn from '../intl/i18n_bn.json';
import hi from '../intl/i18n_hi.json';
import kn from '../intl/i18n_kn.json';

const langKey = 'integrtr-translations-lang';

/// Merge the messages with the default one. Any message not provided will be used from the
/// default one.
function mergeWithDefault(messages) {
  return {
    ...en,
    ...messages,
  };
}

/// Select the messages based on the languages.
function selectMessages(lang) {
  switch (lang) {
    case 'bn':
      return mergeWithDefault(bn);
    case 'de':
      return mergeWithDefault(de);
    case 'hi':
      return mergeWithDefault(hi);
    case 'kn':
      return mergeWithDefault(kn);
    case 'ne':
      return mergeWithDefault(ne);
    case 'en':
    default:
      localStorage.setItem('integrtr-translations-lang', 'en');
      return en;
  }
}

/// Add language code here to add it to the app's language selection options.
export const appLangs = ['default', 'bn', 'de', 'hi', 'kn', 'ne'];

export default createSlice({
  name: 'locale',
  initialState: {
    locale: localStorage.getItem(langKey) ?? 'en',
    messages: selectMessages(localStorage.getItem(langKey) ?? 'en'),
  },
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload;
      localStorage.setItem('integrtr-translations-lang', action.payload);
      state.messages = selectMessages(state.locale);
    },
  },
});

export const inputToolLangs = [
  'hi',
  'ar',
  'bn',
  'dz',
  'el',
  'fa',
  'fil',
  'gu',
  'he',
  'ja',
  'km',
  'kn',
  'ko',
  'ml',
  'mr',
  'ms',
  'my',
  'ne',
  'pa',
  'ps',
  'si',
  'ta',
  'te',
  'ur',
  'zh',
];

const inputToolLangsMap = inputToolLangs.reduce((acc, cur) => {
  acc[cur] = true;
  return acc;
}, {});

export function doesLangRequireInputTool(lang) {
  return inputToolLangsMap[lang] ?? false;
}
