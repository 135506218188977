import { Box, Flex, Heading, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';
import React from 'react';
import { MdArrowForward } from 'react-icons/md';

const CardWrapper = styled(Flex)`
  &:hover {
    background-color: #fafafa;
  }
`;

export default function GithubRepoCard({ name, description, my, onClick }) {
  return (
    <CardWrapper
      borderRadius={4}
      overflow="hidden"
      py={4}
      px={6}
      my={my}
      justifyContent="space-between"
      alignItems="center"
      cursor="pointer"
      border="1px solid #e0e0e0"
      onClick={onClick}
    >
      <Flex flexDirection="column">
        <Heading as="h6" size="sm" d="block">
          {name}
        </Heading>
        <Text fontSize={14} color="gray.500">
          {description}
        </Text>
      </Flex>
      <Box ml={2}>
        <Box as={MdArrowForward} fontSize={24} />
      </Box>
    </CardWrapper>
  );
}
