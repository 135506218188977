import { CSSReset, Text, ThemeProvider } from '@chakra-ui/core';
import { Router } from '@reach/router';
import React from 'react';
import { IntlProvider } from 'react-intl';
import { Provider, useSelector } from 'react-redux';
import Loading from './components/Loading';
import PrivateRoute from './components/PrivateRoute';
import UnauthorizedOnlyRoute from './components/UnauthorizedOnlyRoute';
import AddProjectPage from './pages/addproject';
import ChangeGithubRepoPage from './pages/changegithubrepo';
import ConfigureProjectPage from './pages/configureproject';
import CustomizeProjectPage from './pages/customizeproject';
import FinishInstallationPage from './pages/finishinstallation';
import FirebaseActionPage from './pages/firebaseaction';
import ForgotPasswordPage from './pages/forgotpassword';
import GithubAuthorizePage from './pages/githubauthorize';
import HelpPage from './pages/help';
import HomePage from './pages/home';
import LoginPage from './pages/login';
import NotFoundPage from './pages/notfound';
import ProjectPage from './pages/project';
import ReauthorizeGithubPage from './pages/reauthorizegithub';
import SettingsPage from './pages/settings';
import SignupPage from './pages/signup';
import store from './store';
import { useAuthHydration } from './store/auth';
import theme from './theme';

function AppRoutes() {
  const { hydrated } = useAuthHydration();

  // Setup locales and translations for the app.
  const { locale, messages } = useSelector(
    (state) => state.locale,
    (left, right) => left.locale === right.locale
  );

  return (
    <IntlProvider locale={locale} messages={messages}>
      {hydrated ? (
        <Router>
          <UnauthorizedOnlyRoute component={LoginPage} path="/login" />
          <UnauthorizedOnlyRoute component={SignupPage} path="/signup" />
          <UnauthorizedOnlyRoute component={ForgotPasswordPage} path="/forgot-password" />

          <PrivateRoute component={HomePage} path="/" />
          <PrivateRoute component={SettingsPage} path="/settings" />
          <PrivateRoute component={AddProjectPage} path="/add-project" />
          <PrivateRoute component={ConfigureProjectPage} path="/add-project/configure" />
          <PrivateRoute component={ProjectPage} path="/projects/:projectId" />
          <PrivateRoute component={CustomizeProjectPage} path="/projects/:projectId/customize" />
          <PrivateRoute component={ChangeGithubRepoPage} path="/projects/:projectId/change-repo" />
          <PrivateRoute component={GithubAuthorizePage} path="/gh-authorize" />
          <PrivateRoute
            component={FinishInstallationPage}
            path="/add-project/finish-installation"
          />
          <PrivateRoute component={ReauthorizeGithubPage} path="/reauthorize-github" />

          <HelpPage path="/help" />
          <FirebaseActionPage path="/firebase/action" />
          <NotFoundPage default />
        </Router>
      ) : (
        <Loading />
      )}
    </IntlProvider>
  );
}

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CSSReset />
        {/* Set a default font size for text in the children. The text component does not set it itself. */}
        <Text as="div" fontSize="md">
          <AppRoutes />
        </Text>
      </ThemeProvider>
    </Provider>
  );
}
