import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useAsyncFn, useEffectOnce } from 'react-use';
import axios from '../axios';
import { forgetAuthToken } from '../firebase';

const fbAuth = firebase.auth();

const loggedInKey = 'integrtr-t9n-logged-in';

const auth = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    _id: '',
    name: '',
    email: '',
    github: null,
    loginMechanisms: [],
  },
  reducers: {
    setUser: (state, action) => {
      const payload = action.payload;

      state.isAuthenticated = true;
      state._id = payload._id;
      state.name = payload.name;
      state.email = payload.email;
      state.github = payload.github;
      state.loginMechanisms = payload.loginMechanisms;

      localStorage.setItem(loggedInKey, 'true');
    },
    forgetUser: (state) => {
      state.isAuthenticated = false;
      state._id = '';
      state.name = '';
      state.email = '';
      state.github = null;
      state.loginMechanisms = [];

      localStorage.removeItem(loggedInKey);
    },
    forgetGithub: (state) => {
      state.github = null;
    },
    updateName: (state, action) => {
      state.name = action.payload;
    },
  },
});

export const LoginMechanism = {
  EMAIL: 'email',
  GOOGLE: 'google',
};

// useFetchAuth to initiate fetching of auth.
// [bypassLocalStorageCheck] allows us to fetch auth even when the local storage might not have recorded
// the user as logged in. This is useful when logging in for the first time.
export function useFetchAuth({ bypassLocalStorageCheck = false } = {}) {
  const dispatch = useDispatch();

  const [state, fetchAuth] = useAsyncFn(async () => {
    if (!bypassLocalStorageCheck) {
      // We check this because why hit the profile api if we already know the user is not
      // logged in.
      const isAuth = localStorage.getItem(loggedInKey) === 'true';
      if (!isAuth) {
        return;
      }
    }

    const userResponse = await axios.get('/api/profile');
    dispatch(auth.actions.setUser(userResponse.data));
  }, [dispatch, bypassLocalStorageCheck]);

  return { state, fetchAuth };
}

// useAuthHydration hydrates the auth if a logged in user exists.
// Run this once when the app first loads.
export function useAuthHydration() {
  const [hydrated, setHydrated] = React.useState(false);
  const dispatch = useDispatch();

  const hydrateAuth = async () => {
    try {
      const userResponse = await axios.get('/api/profile');
      dispatch(auth.actions.setUser(userResponse.data));
    } finally {
      setHydrated(true);
    }
  };

  useEffectOnce(() => {
    const isAuth = localStorage.getItem(loggedInKey) === 'true';
    if (!isAuth) {
      setHydrated(true);
      return;
    }

    // We wait to hydrate auth from our backend till firebase fetches the logged in
    // user. Once its fetched, we can use the ID token of the user to access our backend
    // API.
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try {
          await hydrateAuth();
        } finally {
          unsubscribe();
        }
      }
    });

    // If due to some reason the auth state does not change, hidrate the app.
    // If the user is actually not authorized (most likely case), user will be shown the login
    // page.
    // NB: This is something that won't happen. But it might be caused if local storage remembers
    // the user as logged in when the user may not be. Rather than show an infinite loading screen
    // to the user, we direct the user to login page.
    setTimeout(() => {
      setHydrated(true);
      // Wait for 30 seconds for the auth timeout.
    }, 30000);
  });

  return {
    hydrated,
  };
}

/// Login using email and password.
export function useLogin() {
  const dispatch = useDispatch();
  const { fetchAuth } = useFetchAuth({ bypassLocalStorageCheck: true });

  const [state, login] = useAsyncFn(async ({ username, password }) => {
    const user = await fbAuth.signInWithEmailAndPassword(username, password);
    if (user.user.emailVerified) {
      await fetchAuth();

      // Update the login mechanisms for the user. This might have been updated with password reset.
      // Running it once does not hurt per login won't hurt.
      const res = await axios.put('/api/profile/update-login-mechanisms');
      dispatch(auth.actions.setUser(res.data));
      return { ok: true };
    }

    await fbAuth.signOut();
    return {
      emailUnverified: true,
    };
  });

  return { state, login };
}

/// Logout the current auth user.
export function useLogout() {
  const dispatch = useDispatch();

  const logout = React.useCallback(async () => {
    dispatch(auth.actions.forgetUser());
    await fbAuth.signOut();
    forgetAuthToken();
  }, [dispatch]);

  return {
    logout,
  };
}

/// Unlink github account from the user.
export function useUnlinkGithub() {
  const dispatch = useDispatch();

  const [state, unlinkGithub] = useAsyncFn(async () => {
    await axios.post('/api/github/unlink');
    dispatch(auth.actions.forgetGithub());
    return {
      ok: true,
    };
  }, [dispatch]);

  return {
    state,
    unlinkGithub,
  };
}

/// Sign up the user using given details.
export function useSignup() {
  const [state, signup] = useAsyncFn(async ({ name, email, password }) => {
    const user = await fbAuth.createUserWithEmailAndPassword(email, password);
    await user.user.updateProfile({
      displayName: name,
    });
    // Also send verification email.
    await user.user.sendEmailVerification();

    return {
      ok: true,
    };
  });

  return {
    state,
    signup,
  };
}

/// Starts the reset procedure of the password of the user pointed by the email.
export function useSendResetPassword() {
  const [state, sendResetPassword] = useAsyncFn(async ({ email }) => {
    await fbAuth.sendPasswordResetEmail(email);

    return {
      ok: true,
    };
  });

  return {
    state,
    sendResetPassword,
  };
}

/// Resends verification email to the user.
export function useResendVerificationEmail() {
  const [called, setCalled] = React.useState(false);

  const [state, resendVerificationEmail] = useAsyncFn(
    async ({ email, password }) => {
      setCalled(true);

      const user = await fbAuth.signInWithEmailAndPassword(email, password);
      try {
        await user.user.sendEmailVerification();
      } finally {
        await fbAuth.signOut();
      }

      return {
        ok: true,
      };
    },
    [setCalled]
  );

  return {
    state,
    called,
    resendVerificationEmail,
  };
}

/// Update the user info.
export function useUpdateUser() {
  const dispatch = useDispatch();

  const [state, updateUser] = useAsyncFn(async ({ name }) => {
    await axios.put('/api/profile', {
      name,
    });

    dispatch(auth.actions.updateName(name));
    return {
      ok: true,
    };
  });

  return {
    state,
    updateUser,
  };
}

/// Link the current user with google.
export function useLinkWithGoogle() {
  const dispatch = useDispatch();

  const [linkState, linkWithGoogle] = useAsyncFn(async () => {
    const gAuth = new firebase.auth.GoogleAuthProvider();
    await fbAuth.currentUser.linkWithPopup(gAuth);

    const res = await axios.put('/api/profile/update-login-mechanisms');
    dispatch(auth.actions.setUser(res.data));
    return {
      ok: true,
    };
  });

  const [unlinkState, unlinkGoogle] = useAsyncFn(async () => {
    await fbAuth.currentUser.unlink(firebase.auth.GoogleAuthProvider.PROVIDER_ID);

    const res = await axios.put('/api/profile/update-login-mechanisms');
    dispatch(auth.actions.setUser(res.data));
  });

  return {
    linkState,
    linkWithGoogle,
    unlinkState,
    unlinkGoogle,
  };
}

/// Login with google.
export function useLoginWithGoogle() {
  const { fetchAuth } = useFetchAuth({ bypassLocalStorageCheck: true });
  const dispatch = useDispatch();

  const [state, loginWithGoogle] = useAsyncFn(async () => {
    const gAuth = new firebase.auth.GoogleAuthProvider();
    await fbAuth.signInWithPopup(gAuth);

    await fetchAuth();
    // Update the login mechanisms for the user. The user might already exist and the user has tried to
    // login with google for the first time.
    const res = await axios.put('/api/profile/update-login-mechanisms');
    dispatch(auth.actions.setUser(res.data));

    return {
      ok: true,
    };
  });

  return {
    state,
    loginWithGoogle,
  };
}

/// Verify email address of the user.
export function useEmailVerification() {
  const [state, verifyEmail] = useAsyncFn(async ({ code }) => {
    await fbAuth.applyActionCode(code);
  });

  return {
    state,
    verifyEmail,
  };
}

/// Reset password.
export function useResetPassword() {
  const [verifyState, verifyResetCode] = useAsyncFn(async ({ code }) => {
    await fbAuth.verifyPasswordResetCode(code);
    return {
      ok: true,
    };
  }, []);

  const [resetState, resetPassword] = useAsyncFn(async ({ code, password }) => {
    await fbAuth.confirmPasswordReset(code, password);
    return {
      ok: true,
    };
  }, []);

  return {
    verifyState,
    verifyResetCode,
    resetState,
    resetPassword,
  };
}

/// Create a delete request.
export function useSendDeleteRequest() {
  const [state, sendDeleteRequest] = useAsyncFn(async () => {
    await axios.post('/api/profile/delete-request');
    return {
      ok: true,
    };
  });

  return {
    state,
    sendDeleteRequest,
  };
}

export default auth;
