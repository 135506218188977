import { Box, Checkbox, Input, Tag, TagLabel } from '@chakra-ui/core';
import { DisplayNames } from '@formatjs/intl-displaynames';
import React from 'react';
import { Field, FormSpy } from 'react-final-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

export default function LanguageInput({ namePrefix, disableTags, shownLanguages }) {
  const [search, setSearch] = React.useState('');
  const languages = useSelector((state) => state.project.allLanguages);

  const finalLanguages = shownLanguages || languages;
  const intl = useIntl();

  return (
    <Box border="1px" borderColor="gray.200" p={2} borderRadius="sm">
      {!disableTags && (
        <FormSpy subscription={{ values: true }}>
          {({ values }) => {
            const supportedLanguages = finalLanguages.filter((l) => !!values[`${namePrefix}${l}`]);

            return (
              <Box mb={supportedLanguages.length > 0 ? 2 : 0}>
                {supportedLanguages.map((lang) => (
                  <Tag key={lang} mr={2}>
                    <TagLabel>{new DisplayNames(intl.locale).of(lang)}</TagLabel>
                  </Tag>
                ))}
              </Box>
            );
          }}
        </FormSpy>
      )}

      <FormattedMessage id="SEARCH_LANGUAGE">
        {(value) => (
          <Input placeholder={value} value={search} onChange={(ev) => setSearch(ev.target.value)} />
        )}
      </FormattedMessage>

      <Box border="1px" borderColor="gray.200" borderRadius="sm" mt={2}>
        <Box height={300} overflowY="auto" position="relative" bg="gray.50">
          {finalLanguages
            .map((lang) => ({
              lang,
              text: new DisplayNames(intl.locale).of(lang),
            }))
            .filter((l) => l.text.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1)
            .map((l) => (
              <Box key={l.lang} mb={1}>
                <Field
                  name={`${namePrefix}${l.lang}`}
                  render={({ input }) => (
                    <Checkbox
                      px={4}
                      my={2}
                      isChecked={input.value}
                      onChange={() => input.onChange(!input.value)}
                    >
                      {l.text}
                    </Checkbox>
                  )}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}
