import { Flex, Box, Text } from '@chakra-ui/core';
import Spinner from 'react-spinkit';
import React from 'react';

export default function Loading() {
  return (
    <Flex height="100vh" flexDirection="column" justifyContent="center" alignItems="center">
      <Spinner name="cube-grid" color="#13BE91" fadeIn="none" />
      <Box mt={3}>
        <Text color="gray.500">Loading...</Text>
      </Box>
    </Flex>
  );
}
