import { useToast } from '@chakra-ui/core';
import React from 'react';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';
import axios from '../axios';
import { useFetchProject } from '../store/project';

export default function TranslationUpdateForm({ lang, translatedTexts, children, onAfterSave }) {
  const pushToast = useToast();
  const currentProject = useSelector((state) => state.project.currentProject);
  const { fetchProject } = useFetchProject();

  return (
    <Form
      initialValues={Object.keys(currentProject?.default?.json ?? {}).reduce((acc, cur) => {
        acc[cur] = translatedTexts?.[cur] || currentProject?.translate?.json?.[cur] || '';
        return acc;
      }, {})}
      subscription={{}}
      onSubmit={async (state) => {
        await axios.put(
          `/api/projects/${currentProject.project._id}/${currentProject.translate?.lang ?? lang}`,
          {
            json: state,
          }
        );

        await fetchProject({
          projectId: currentProject.project._id,
          lang,
        });
        onAfterSave();

        pushToast({
          description: 'Successfully saved the translations.',
          status: 'success',
        });
      }}
    >
      {children}
    </Form>
  );
}
