import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Text,
  useToast,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';
import { useRefreshProject } from '../store/project';

export default function RefreshConfirmation({ isOpen, onClose, onReset }) {
  const [handleError, setHandleError] = React.useState(false);

  const pushToast = useToast();
  const currentProject = useSelector((state) => state.project.currentProject);
  const projectId = currentProject?.project?._id;
  const lang = currentProject?.translate?.lang;

  const cancelRef = React.useRef();

  const { state, refreshProject } = useRefreshProject();
  const onRefreshProject = React.useCallback(async () => {
    const res = await refreshProject({ projectId, lang });
    if (res.ok) {
      onReset();
      pushToast({
        description: 'Successfully refreshed the project.',
        status: 'success',
      });
      return;
    }

    setHandleError(true);
  }, [refreshProject, projectId, lang, onReset, pushToast]);

  React.useEffect(() => {
    if (handleError) {
      if (state.error.response?.data?.type === 'github_token_revoked') {
        pushToast({
          description: 'Github integration with this has been revoked.',
          status: 'error',
        });
        navigate('/reauthorize-github');
      } else {
        pushToast({
          description: state.error.response?.data?.message ?? 'Something bad happened.',
          status: 'error',
        });
      }

      setHandleError(false);
    }
  }, [handleError, setHandleError, state.error, pushToast]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={state.loading ? null : onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" pt={4} pb={2}>
        <AlertDialogBody>
          Are you sure you want to refresh this project? This will fetch new translations from
          github while discarding the existing ones.
          <Text mt={2} fontSize="md" color="red.500">
            You last refreshed at{' '}
            <FormattedDate
              value={currentProject?.project?.refreshedAt ?? currentProject?.project?.createdAt}
              year="numeric"
              month="long"
              day="2-digit"
              hour="numeric"
              minute="numeric"
            />
            .
          </Text>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} isDisabled={state.loading}>
            Cancel
          </Button>
          <Button
            variantColor="red"
            isLoading={state.loading}
            onClick={async () => {
              await onRefreshProject();
              onClose();
            }}
            ml={3}
          >
            Ok
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
