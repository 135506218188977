import { Box, Flex, Heading, Tag, Text } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { DisplayNames } from '@formatjs/intl-displaynames';
import { Link } from '@reach/router';
import React from 'react';
import { MdApps, MdArrowForward } from 'react-icons/md';
import { FormattedMessage, FormattedPlural, useIntl } from 'react-intl';

const CardWrapper = styled(Flex)`
  &:hover {
    background-color: #fafafa;
  }
`;

function FormatLanguage({ count }) {
  return (
    <>
      +{count}{' '}
      <FormattedPlural
        value={count}
        one={<FormattedMessage id="LANGUAGE_SINGULAR" />}
        other={<FormattedMessage id="LANGUAGE_PLURAL" />}
      />
    </>
  );
}

export default function ProjectCard({
  name,
  isOwner,
  githubRepo,
  supportedLanguages = [],
  to = '#',
}) {
  const intl = useIntl();

  return (
    <CardWrapper
      as={Link}
      to={to}
      boxShadow="0 0 4px 2px rgba(0,0,0,.1)"
      borderRadius={4}
      overflow="hidden"
      py={4}
      px={6}
      mx={4}
      mt={4}
      justifyContent="space-between"
      alignItems="center"
    >
      <Box as={MdApps} fontSize={22} color="green.500" mr={6} />
      <Flex
        justifyContent={['center', null, 'space-between']}
        flex={1}
        alignItems={['space-between', null, 'center']}
        flexDirection={['column', null, 'row']}
      >
        <Flex alignItems="center">
          <Flex flexDirection="column">
            <Flex alignItems="center">
              <Heading as="h6" size="sm" d="block">
                {name}
              </Heading>
              {isOwner && (
                <Tag
                  ml={2}
                  size="sm"
                  bg="green.300"
                  color="white"
                  minHeight="1rem"
                  paddingX=".25rem"
                >
                  <FormattedMessage id="OWNER" />
                </Tag>
              )}
            </Flex>
            <Text fontSize={14} color="gray.500" display={['none', null, 'block']}>
              <FormattedMessage id="GITHUB_REPO_NAME" values={{ repo: githubRepo }} />
            </Text>
          </Flex>
        </Flex>

        <Box display={['none', null, 'block']}>
          {supportedLanguages.slice(0, 5).map((lang) => (
            <Tag mr={2} key={lang} size="sm">
              {new DisplayNames(intl.locale).of(lang)}
            </Tag>
          ))}

          {supportedLanguages.length > 5 && (
            <Tag size="sm" mr={2}>
              <FormatLanguage count={supportedLanguages.length - 5} />
            </Tag>
          )}
        </Box>

        <Box display={['block', null, 'none']} mt={2}>
          {supportedLanguages.slice(0, 2).map((lang) => (
            <Tag mr={2} key={lang} size="sm">
              {new DisplayNames(intl.locale).of(lang)}
            </Tag>
          ))}

          {supportedLanguages.length > 2 && (
            <Tag size="sm" mr={2}>
              <FormatLanguage count={supportedLanguages.length - 2} />
            </Tag>
          )}
        </Box>
      </Flex>

      <Box as={MdArrowForward} fontSize={24} />
    </CardWrapper>
  );
}
