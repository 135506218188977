import { createSlice } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';
import { useDispatch } from 'react-redux';
import { useAsyncFn } from 'react-use';
import axios from '../axios';

const project = createSlice({
  name: 'project',
  initialState: {
    allProjects: [],
    currentProject: null,
    allLanguages: [],
  },
  reducers: {
    setAllProjects: (state, action) => {
      state.allProjects = action.payload;
    },
    setCurrentProject: (state, action) => {
      state.currentProject = action.payload;
    },
    setAllLanguages: (state, action) => {
      state.allLanguages = action.payload;
    },
  },
});

// useFetchAllProjects fetches the projects of the current user.
export function useFetchAllProjects() {
  const dispatch = useDispatch();

  const [state, fetchAllProjects] = useAsyncFn(async () => {
    const res = await axios.get('/api/projects');
    dispatch(project.actions.setAllProjects(res.data));
  }, [dispatch]);

  return {
    state,
    fetchAllProjects,
  };
}

// useFetchProject fetches the project in question with language translations for the
// given lang.
// This also fetches us the default language for the given project.
export function useFetchProject() {
  const dispatch = useDispatch();

  const [state, fetchProject] = useAsyncFn(
    async ({ projectId, lang }) => {
      const res = await axios.get(`/api/projects/${projectId}?lang=${lang ?? ''}`);
      dispatch(project.actions.setCurrentProject(res.data));
    },
    [dispatch]
  );

  return {
    state,
    fetchProject,
  };
}

/// Fetch all the languages supported for translation by our system.
export function useFetchAllLanguages() {
  const dispatch = useDispatch();
  const [state, fetch] = useAsyncFn(async () => {
    const res = await axios.get('/api/projects/languages');
    dispatch(project.actions.setAllLanguages(res.data));
  }, [dispatch]);

  return {
    fetchAllLanguages: fetch,
    state,
  };
}

/// Download the translations as a zip for a project.
export function useDownloadZip() {
  const [state, download] = useAsyncFn(async ({ projectId, projectName, langs }) => {
    const res = await axios.get(
      `/api/projects/${projectId}/download?langs=${langs ? langs.join(',') : ''}`,
      {
        responseType: 'blob',
      }
    );
    if (res.status === 204) {
      // No content or file was sent.
      return;
    }

    fileDownload(res.data, `${projectName}-${Date.now()}.zip`);
  }, []);

  return {
    downloadZip: download,
    state,
  };
}

/// Update the project.
export function useUpdateProject() {
  const { fetchProject } = useFetchProject();

  const [state, updateProject] = useAsyncFn(
    async ({ projectId, ...body }) => {
      await axios.put(`/api/projects/${projectId}`, body);
      await fetchProject({ projectId });
    },
    [fetchProject]
  );

  return { state, updateProject };
}

/// Refresh the project.
export function useRefreshProject() {
  const { fetchProject } = useFetchProject();

  const [state, refreshProject] = useAsyncFn(
    async ({ projectId, lang }) => {
      await axios.put(`/api/projects/${projectId}/refresh`);
      await fetchProject({ projectId, lang });
      return {
        ok: true,
      };
    },
    [fetchProject]
  );

  return {
    state,
    refreshProject,
  };
}

/// Create PR for project.
export function useCreatePR() {
  const [state, createPR] = useAsyncFn(async ({ projectId, force }) => {
    const pr = await axios.post(`/api/projects/${projectId}/create-pr`, {
      force,
    });
    return {
      prUrl: pr.data?.prUrl,
      noPr: Number(pr.status) === 204,
    };
  }, []);

  return {
    state,
    createPR,
  };
}

/// Delete a single project.
export function useDeleteProject() {
  const [state, deleteProject] = useAsyncFn(async ({ projectId }) => {
    await axios.delete(`/api/projects/${projectId}`);
  }, []);

  return {
    state,
    deleteProject,
  };
}

/// Update the github repo of the project.
export function useUpdateGithubRepo() {
  const { fetchProject } = useFetchProject();

  const [state, updateGithubRepo] = useAsyncFn(
    async ({ projectId, github }) => {
      await axios.put(`/api/projects/${projectId}/relink-github-repo`, github);
      await fetchProject({ projectId });
      return { ok: true };
    },
    [fetchProject]
  );

  return {
    state,
    updateGithubRepo,
  };
}

/// Resend inivitation email to an email.
export function useResendInvitationEmail() {
  const [state, resendInvitationEmail] = useAsyncFn(async ({ projectId, email }) => {
    await axios.post(`/api/projects/${projectId}/resend-invitation`, {
      email,
    });
    return {
      ok: true,
    };
  });

  return {
    state,
    resendInvitationEmail,
  };
}

export default project;
