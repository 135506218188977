import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Text,
} from '@chakra-ui/core';
import React from 'react';

export default function PrivacyPolicy({ isOpen, onClose }) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent maxWidth={[null, null, null, 800]}>
        <AlertDialogHeader>Privacy Policy</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <Text>
            Your privacy is important to us. It is INTEGRTR's policy to respect your privacy
            regarding any information we may collect from you across our
            website, http://translate.integrtr.com, and other sites we own and operate.
          </Text>
          <Text mt={2}>
            We only ask for personal information when we truly need it to provide a service to you.
            We collect it by fair and lawful means, with your knowledge and consent. We also let you
            know why we’re collecting it and how it will be used.
          </Text>
          <Text mt={2}>We only share information with the following third parties. </Text>
          <Text mt={2}>
            We only share and disclose your information with the following third parties. We have
            categorized each party so that you may be easily understand the purpose of our data
            collection and processing practices. If we have processed your data based on your
            consent and you wish to revoke your consent, please contact us. 
          </Text>
          <Text mt={1} ml={4}>
            1. Allow Users to Connect to their Third-Party Accounts 
          </Text>
          <Text ml={8}>- GitHub account </Text>
          <Text ml={4}>2. User Account Registration and Authentication </Text>
          <Text ml={8}>
            - Facebook Login, GitHub OAuth, Google OAuth 2.0, Google Sign-In, Firebase
          </Text>
          <Text ml={4}>3. Web and Mobile Analytics </Text>
          <Text ml={8}>- Google Analytics </Text>
          <Text ml={4}>4. Website Performance Monitoring </Text>
          <Text ml={8}>- Sentry </Text>
          <Text ml={8}>- LogDNA </Text>
          <Text ml={4}>5. Infrastructure </Text>
          <Text ml={8}>- Heroku</Text>
          <Text ml={8}>- Amazon Web Services </Text>
          <Text mt={4}>
            We only retain collected information for as long as necessary to provide you with your
            requested service. What data we store, we’ll protect within commercially acceptable
            means to prevent loss and theft, as well as unauthorized access, disclosure, copying,
            use or modification.
          </Text>
          <Text mt={2}>
            We don’t share any personally identifying information publicly or with third-parties,
            except when required to by law.
          </Text>
          <Text mt={2}>
            Our website may link to external sites that are not operated by us. Please be aware that
            we have no control over the content and practices of these sites and cannot accept
            responsibility or liability for their respective privacy policies.
          </Text>
          <Text mt={2}>
            You are free to refuse our request for your personal information, with the understanding
            that we may be unable to provide you with some of your desired services.
          </Text>
          <Text mt={2}>
            Your continued use of our website will be regarded as acceptance of our practices around
            privacy and personal information. If you have any questions about how we handle user
            data and personal information, feel free to contact us.
          </Text>
          <Text mt={2}>This policy is effective as of 1 June 2020.</Text>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}
