import { Box, Flex, Heading, Link, Spinner, Text, useDisclosure } from '@chakra-ui/core';
import { useParams } from '@reach/router';
import React from 'react';
import { MdOpenInNew } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import CreatePR from '../components/CreatePR';
import Layout from '../components/Layout';
import Navigation from '../components/Navigation';
import ProjectTitle from '../components/ProjectTitle';
import RefreshConfirmation from '../components/RefreshConfirmation';
import SEO from '../components/SEO';
import TranslationTable from '../components/TranslationTable';
import TranslationUpdateForm from '../components/TranslationUpdateForm';
import { useFetchProject } from '../store/project';
import { useTranslateAllText } from '../store/translate';

export default function ProjectPage() {
  const { isOpen: prOpen, onClose: prOnClose, onOpen: prOnOpen } = useDisclosure();
  const { isOpen: rfOpen, onClose: rfOnClose, onOpen: rfOnOpen } = useDisclosure();

  const { projectId } = useParams();
  const currentProject = useSelector((state) => state.project.currentProject);

  const [isTranslatedAll, setIsTranslatedAll] = React.useState(false);
  const [translatedTexts, setTranslatedTexts] = React.useState({});
  const { state: translateAllState, translateAllTexts } = useTranslateAllText();

  const [lang, setLang] = React.useState('');
  React.useEffect(() => {
    if (lang === '' && currentProject?.project?.supportedLanguages?.length > 0) {
      setLang(currentProject.project.supportedLanguages[0]);
    }
  }, [lang, setLang, currentProject]);

  const { state: projectState, fetchProject } = useFetchProject();
  React.useEffect(() => {
    fetchProject({ projectId, lang });
  }, [fetchProject, projectId, lang]);

  return (
    <Layout
      navigation={
        <Navigation
          middle={
            <Box textAlign="center" display={['none', null, null, 'block']}>
              <Heading as="h4" fontSize="lg" color="white">
                <FormattedMessage
                  id="PROJECT_N"
                  values={{ name: currentProject?.project?.name ?? '' }}
                />
              </Heading>
              {currentProject?.project?.repoUrl ? (
                <Link href={currentProject?.project?.repoUrl} target="_blank" color="gray.200">
                  {currentProject?.project?.repoUrl} <Box as={MdOpenInNew} display="inline" />
                </Link>
              ) : (
                ''
              )}
            </Box>
          }
        />
      }
    >
      <FormattedMessage id="PROJECT_N" values={{ name: currentProject?.project?.name ?? '' }}>
        {(value) => <SEO title={value} />}
      </FormattedMessage>

      <TranslationUpdateForm
        lang={lang}
        translatedTexts={translatedTexts}
        onAfterSave={() => {
          setIsTranslatedAll(false);
          setTranslatedTexts({});
        }}
      >
        {({ handleSubmit }) => (
          <>
            <ProjectTitle
              prOnOpen={prOnOpen}
              rfOnOpen={rfOnOpen}
              isTranslatedAll={isTranslatedAll}
              setIsTranslatedAll={setIsTranslatedAll}
              setTranslatedTexts={setTranslatedTexts}
              translateAllState={translateAllState}
              translateAllTexts={translateAllTexts}
              lang={lang}
              setLang={(lang) => {
                setLang(lang);
                setIsTranslatedAll(false);
                setTranslatedTexts({});
              }}
            />

            <TranslationTable
              isRefreshing={translateAllState.loading}
              isTranslatedAll={isTranslatedAll}
              handleSubmit={handleSubmit}
              lang={lang}
            />
          </>
        )}
      </TranslationUpdateForm>
      {projectState.loading && (
        <Flex
          justifyContent="center"
          alignItems="center"
          position="fixed"
          width="100%"
          height="100vh"
          top={0}
          left={0}
        >
          <Box pt="1.8rem" pb="1.3rem" px={6} bg="white" shadow="lg" textAlign="center">
            <Spinner />
            <Text fontSize="md" color="gray.500" mt={2}>
              Loading...
            </Text>
          </Box>
        </Flex>
      )}

      <CreatePR isOpen={prOpen} projectId={projectId} onClose={prOnClose} />
      <RefreshConfirmation
        isOpen={rfOpen}
        onClose={rfOnClose}
        onReset={() => {
          setIsTranslatedAll(false);
          setTranslatedTexts({});
        }}
      />
    </Layout>
  );
}
