import {
  Box,
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { DisplayNames } from '@formatjs/intl-displaynames';
import { navigate, useParams } from '@reach/router';
import React from 'react';
import { FormSpy } from 'react-final-form';
import {
  MdCloudDownload,
  MdMoreVert,
  MdOpenInNew,
  MdPublish,
  MdRefresh,
  MdSettings,
  MdTranslate,
} from 'react-icons/md';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import PageTitle from '../components/PageTitle';
import { doesLangRequireInputTool } from '../store/locale';
import { useDownloadZip } from '../store/project';
import { translatableLanguages } from '../store/translate';
import DiscardConfirmation from './DiscardConfirmation';
import ExportDialog from './ExportDialog';

function LangSelect({ languages = [], lang, setLang, isTranslatedAll }) {
  const intl = useIntl();
  const [interLang, setInterLang] = React.useState(lang);
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <DiscardConfirmation
        isOpen={isOpen}
        onClose={onClose}
        onOk={() => {
          setLang(interLang);
          onClose();
        }}
      />
      <FormSpy subscription={{ dirty: true }}>
        {({ dirty }) => (
          <Select
            value={lang}
            onChange={(ev) => {
              if (dirty || isTranslatedAll) {
                setInterLang(ev.target.value);
                onOpen();
                return;
              }

              setLang(ev.target.value);
            }}
          >
            {languages?.map((l) => (
              <option key={l} value={l}>
                {new DisplayNames(intl.locale).of(l)}
              </option>
            ))}
          </Select>
        )}
      </FormSpy>
    </>
  );
}

export default function ProjectTitle({
  prOnOpen,
  rfOnOpen,
  isTranslatedAll,
  setIsTranslatedAll,
  setTranslatedTexts,
  translateAllState,
  translateAllTexts,
  lang,
  setLang,
}) {
  const { isOpen: exportOpen, onOpen: onExportOpen, onClose: onExportClose } = useDisclosure();

  const currentProject = useSelector((state) => state.project.currentProject);
  const userId = useSelector((state) => state.auth._id);

  const pushToast = useToast();
  const { projectId } = useParams();
  const canAutoTranslate = translatableLanguages.includes(lang);
  const { state: downloadState, downloadZip } = useDownloadZip();

  const onTranslateAll = React.useCallback(async () => {
    const keys = Object.keys(currentProject?.default?.json);
    const texts = keys.map((key) =>
      /^%COMMENT\+\d+%$/.test(key) ? '' : currentProject.default.json[key] || ''
    );

    const res = await translateAllTexts({ texts, targetLang: lang, projectId });

    if (res.ok) {
      setTranslatedTexts(
        keys.reduce((acc, cur, index) => {
          acc[cur] = res.translated[index];
          return acc;
        }, {})
      );

      setIsTranslatedAll(true);
      pushToast({
        description: 'Successfully translated all the texts.',
        status: 'success',
      });
      return;
    }

    pushToast({
      description: 'Failed to translate all the texts.',
      status: 'error',
    });
  }, [
    pushToast,
    setTranslatedTexts,
    translateAllTexts,
    lang,
    currentProject,
    setIsTranslatedAll,
    projectId,
  ]);

  const onCustomize = () => navigate(`/projects/${projectId}/customize`);

  const inputTools = doesLangRequireInputTool(lang) ? (
    <Text color="gray.500" fontSize="sm" mt={1}>
      You may want to use{' '}
      <Link href="https://www.google.com/inputtools/try/" target="_blank" color="green.500">
        Google Input Tools
      </Link>{' '}
      to help you type in this language.
    </Text>
  ) : null;

  const actionButtons = (
    <Box display="flex">
      {userId === currentProject?.project?.userId?._id && (
        <Box ml={3}>
          <Button
            leftIcon={MdSettings}
            onClick={onCustomize}
            isDisabled={translateAllState.loading}
          >
            <FormattedMessage id="CUSTOMIZE" />
          </Button>
        </Box>
      )}

      {userId === currentProject?.project?.userId?._id &&
        currentProject?.project?.translationsPath && (
          <Box ml={3}>
            <Button isDisabled={translateAllState.loading} onClick={rfOnOpen}>
              <FormattedMessage id="REFRESH" />
            </Button>
          </Box>
        )}

      <Box ml={3}>
        {canAutoTranslate ? (
          <Button
            leftIcon={MdTranslate}
            isLoading={translateAllState.loading}
            onClick={onTranslateAll}
            variantColor="blueGray"
          >
            <FormattedMessage id="TRANSLATE_ALL" />
          </Button>
        ) : (
          <FormattedMessage id="LANGUAGE_NOT_SUPPORTED">
            {(value) => (
              <Tooltip label={value} zIndex={1000}>
                <Button
                  leftIcon={MdTranslate}
                  bg="#EDF2F7"
                  color="gray.600"
                  opacity={0.4}
                  _hover={{ bg: 'inherit' }}
                  _active={{ bg: 'inherit' }}
                  _focus={{ boxShadow: 'none' }}
                >
                  <FormattedMessage id="TRANSLATE_ALL" />
                </Button>
              </Tooltip>
            )}
          </FormattedMessage>
        )}
      </Box>

      <Menu>
        <MenuButton ml={4}>
          <Box as={MdMoreVert} fontSize={22} />
        </MenuButton>
        <MenuList zIndex={800} placement="bottom-end">
          <MenuItem
            onClick={onExportOpen}
            disabled={
              !currentProject?.project?.name || translateAllState.loading || downloadState.loading
            }
          >
            <MdCloudDownload />
            <Text ml={3}>
              <FormattedMessage id="EXPORT" />
            </Text>
          </MenuItem>

          <MenuItem onClick={prOnOpen}>
            <MdPublish />
            <Text ml={3}>
              <FormattedMessage id="CREATE_PR" />
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );

  return (
    <>
      <PageTitle
        title={
          <FormattedMessage id="PROJECT_N" values={{ name: currentProject?.project?.name ?? '' }} />
        }
        subtitle={
          currentProject?.project?.repoUrl ? (
            <Link href={currentProject?.project?.repoUrl} target="_blank">
              {currentProject?.project?.repoUrl} <Box as={MdOpenInNew} display="inline" />
            </Link>
          ) : (
            ''
          )
        }
        prepend={
          <>
            <Flex alignItems="center">
              <Text>
                <FormattedMessage id="LANGUAGE" />:
              </Text>
              <Box ml={2}>
                <LangSelect
                  languages={currentProject?.project?.supportedLanguages}
                  lang={lang}
                  setLang={setLang}
                  isTranslatedAll={isTranslatedAll}
                />
              </Box>
            </Flex>
            {inputTools}
          </>
        }
        backTo="/"
        boxShadow={['0 2px 4px rgba(0,0,0,.1)', null, 'none']}
        append={
          <>
            <Box display={['none', null, null, 'block']}>{actionButtons}</Box>

            <Box display={['block', null, null, 'none']}>
              <Menu>
                <MenuButton>
                  <Box as={MdMoreVert} fontSize={22} />
                </MenuButton>
                <MenuList zIndex={800} placement="top-end">
                  <MenuItem onClick={onCustomize}>
                    <MdSettings />
                    <Text ml={3}>
                      <FormattedMessage id="CUSTOMIZE" />
                    </Text>
                  </MenuItem>
                  {userId === currentProject?.project?.userId?._id &&
                    currentProject?.project?.translationsPath && (
                      <MenuItem onClick={rfOnOpen}>
                        <MdRefresh />
                        <Text ml={3}>
                          <FormattedMessage id="REFRESH" />
                        </Text>
                      </MenuItem>
                    )}
                  <MenuItem onClick={onTranslateAll}>
                    <MdTranslate />
                    <Text ml={3}>
                      <FormattedMessage id="TRANSLATE_ALL" />
                    </Text>
                  </MenuItem>
                  <MenuItem onClick={onExportOpen}>
                    <MdCloudDownload />
                    <Text ml={3}>
                      <FormattedMessage id="EXPORT" />
                    </Text>
                  </MenuItem>
                  <MenuItem onClick={prOnOpen}>
                    <MdPublish />
                    <Text ml={3}>
                      <FormattedMessage id="CREATE_PR" />
                    </Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </>
        }
        bottom={
          <>
            <Flex justifyContent="flex-end" mr={4} pb={1}>
              <Text fontSize="sm" color="gray.500">
                <FormattedMessage id="LAST_UPDATED" />:{' '}
                <FormattedDate
                  value={currentProject?.translate?.updatedAt ?? currentProject?.project?.updatedAt}
                  year="numeric"
                  month="long"
                  day="2-digit"
                  hour="numeric"
                  minute="numeric"
                />
              </Text>
            </Flex>

            <Box display={['block', null, null, 'none']} mt={2} pb={2} px={4}>
              <Flex justifyContent="flex-end" alignItems="center">
                <Text color="gray.500">
                  <FormattedMessage id="LANGUAGE" />:
                </Text>
                <Box ml={2}>
                  <LangSelect
                    languages={currentProject?.project?.supportedLanguages}
                    lang={lang}
                    setLang={setLang}
                    isTranslatedAll={isTranslatedAll}
                  />
                </Box>
              </Flex>
              <Box textAlign="right">{inputTools}</Box>
            </Box>
          </>
        }
      />
      <ExportDialog
        languages={currentProject?.project?.supportedLanguages ?? []}
        isOpen={exportOpen}
        onClose={onExportClose}
        onDownload={(langs) =>
          downloadZip({ projectId, projectName: currentProject.project.name, langs })
        }
      />
    </>
  );
}
