import { Box, Button, Heading, Text, useToast } from '@chakra-ui/core';
import React from 'react';
import { FcGoogle } from 'react-icons/fc';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { LoginMechanism, useLinkWithGoogle } from '../store/auth';

export default function SocialLinking() {
  const pushToast = useToast();
  const loginMechanisms = useSelector((state) => state.auth.loginMechanisms);
  const isGoogle = loginMechanisms.includes(LoginMechanism.GOOGLE);
  const { linkState, linkWithGoogle, unlinkState, unlinkGoogle } = useLinkWithGoogle();

  const [error, setError] = React.useState(false);

  const link = React.useCallback(async () => {
    const res = await linkWithGoogle();
    if (res.ok) {
      pushToast({
        description: 'Successfully linked with google sign in.',
        status: 'success',
      });
      return;
    }

    setError(true);
  }, [linkWithGoogle, setError, pushToast]);

  React.useEffect(() => {
    if (error) {
      setError(false);
      pushToast({
        description: linkState.error?.message ?? 'Something bad happened.',
        status: 'error',
      });
    }
  }, [error, setError, linkState, pushToast]);

  return loginMechanisms.length !== 1 || loginMechanisms.includes(LoginMechanism.EMAIL) ? (
    <Box mx={4} mt={8}>
      <Heading as="h5" fontSize="md" mb={4}>
        <FormattedMessage id="GOOGLE_INTEGRATION" />
      </Heading>
      <Button
        onClick={isGoogle ? unlinkGoogle : link}
        isLoading={linkState.loading || unlinkState.loading}
      >
        <Box as={FcGoogle} />
        <Text ml={3}>
          {isGoogle ? (
            <FormattedMessage id="UNLINK_GOOGLE" />
          ) : (
            <FormattedMessage id="LINK_GOOGLE" />
          )}
        </Text>
      </Button>
      <Text mt={1} color="gray.500">
        <FormattedMessage id="LINK_GOOGLE_MESSAGE" />
      </Text>
    </Box>
  ) : null;
}
