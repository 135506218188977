import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Link,
  useDisclosure,
} from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function NotMyAccountConfirmation({ onOk }) {
  const cancelRef = React.useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Link ml={3} color="red.500" onClick={onOpen}>
        <FormattedMessage id="NOT_MY_ACCOUNT" />
      </Link>

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay />
        <AlertDialogCloseButton />
        <AlertDialogContent>
          <AlertDialogHeader>
            <FormattedMessage id="NOT_MY_GITHUB_ACCOUNT_Q" />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage id="NOT_MY_GITHUB_ACCOUNT_MESSAGE" />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button
              variantColor="red"
              onClick={async () => {
                await onOk();
                onClose();
              }}
              ml={3}
            >
              <FormattedMessage id="OK" />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
