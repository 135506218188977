import { createSlice } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import axios from '../axios';

const gh = createSlice({
  name: 'github',
  initialState: {
    newProject: {
      name: '',
      fullName: '',
      description: '',
      url: '',
    },
    repos: [],
    installations: [],
  },
  reducers: {
    setRepos: (state, action) => {
      state.repos = action.payload;
    },
    setInstallations: (state, action) => {
      state.installations = action.payload;
    },
    setNewProject: (state, action) => {
      state.newProject = action.payload;
    },
  },
});

/// useFetchGithubInstallations gets the installations for the user.
export function useFetchGithubInstallations() {
  const github = useSelector((state) => state.auth.github);
  const dispatch = useDispatch();

  const [state, fetchGithubInstallations] = useAsyncFn(async () => {
    if (!github) {
      return;
    }

    const res = await axios.get(`/api/github/installations`);
    dispatch(gh.actions.setInstallations(res.data));
  }, [dispatch, github]);

  return {
    state,
    fetchGithubInstallations,
  };
}

// useFetchGithubRepos fetches the repos of the current user.
export function useFetchGithubRepos() {
  const github = useSelector((state) => state.auth.github);
  const dispatch = useDispatch();

  const [state, fetchGithubRepos] = useAsyncFn(
    async ({ installationId } = {}) => {
      if (!installationId) {
        return;
      }

      if (!github) {
        return;
      }

      const res = await axios.get(`/api/github/installations/${installationId}/repos`);
      dispatch(gh.actions.setRepos(res.data));
    },
    [dispatch, github]
  );

  return {
    state,
    fetchGithubRepos,
  };
}

/// Checks whether the installation belongs to the logged in user.
export function useCheckInstallation() {
  const [state, checkInstallation] = useAsyncFn(async ({ installationId }) => {
    const res = await axios.get(`/api/github/check-installation?installationId=${installationId}`);
    return res.data;
  });

  return {
    state,
    checkInstallation,
  };
}

/// This hook fetches the github repo branches.
export function useFetchGithubRepoBranches() {
  const [state, fetchGithubRepoBranches] = useAsyncFn(async ({ repoOwner, repoName }) => {
    const res = await axios.get(`/api/github/repos/${repoOwner}/${repoName}/branches`);
    return res.data;
  });

  return {
    state,
    fetchGithubRepoBranches,
  };
}

export default gh;
