import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Heading,
} from '@chakra-ui/core';
import React from 'react';

function MainHeading(props) {
  return <Heading as="h5" fontSize="md" {...props} />;
}

function FirstLevel(props) {
  return <Box as="p" ml={4} {...props} />;
}

function SecondLevel(props) {
  return <Box as="p" ml={8} {...props} />;
}

export default function TermsAndConditions({ isOpen, onClose }) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />
      <AlertDialogContent maxWidth={[null, null, null, 800]}>
        <AlertDialogHeader>Terms and Conditions</AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody>
          <MainHeading>1. Terms</MainHeading>
          <Box as="p" mt={2}>
            By accessing the website at http://translate.integrtr.com, you are agreeing to be bound
            by these terms of service, all applicable laws and regulations, and agree that you are
            responsible for compliance with any applicable local laws. If you do not agree with any
            of these terms, you are prohibited from using or accessing this site. The materials
            contained in this website are protected by applicable copyright and trademark law.
          </Box>
          <MainHeading mt={4}>2. Use Licences</MainHeading>
          <FirstLevel mt={2}>
            a. Permission is granted to use translate.integrtr.com for personally and -commercial
            purposes. This is the grant of a license, not a transfer of title, and under this
            license you may not:
          </FirstLevel>
          <SecondLevel>i. modify or copy the materials;</SecondLevel>
          <SecondLevel>
            ii. use the service for destructive, pornographic and illegal purposes.
          </SecondLevel>
          <SecondLevel>
            iii. attempt to decompile or reverse engineer any software contained on INTEGRTR's
            website;
          </SecondLevel>
          <SecondLevel>
            iv. remove any copyright or other proprietary notations from the materials; or
          </SecondLevel>
          <SecondLevel>
            v. transfer the materials to another person or "mirror" the materials on any other
            server.
          </SecondLevel>
          <FirstLevel>
            b. This license shall automatically terminate if you violate any of these restrictions
            and may be terminated by INTEGRTR at any time. Upon terminating your viewing of these
            materials or upon the termination of this license, you must destroy any downloaded
            materials in your possession whether in electronic or printed format.
          </FirstLevel>
          <FirstLevel>
            c. By using translate.integrtr.com, you give us permission to use your company’s name
            and/or logo on our website and other marketing materials identifying you as one of our
            users. No endorsement or affiliation is implied, and your trademarks and copyrights
            remain your property.
          </FirstLevel>
          <FirstLevel>
            d. You must have the authority to bind yourself to these Terms. If you are registering
            for an account on behalf of a company or other legal entity, you represent and warrant
            that you have the authority to bind such entity, its affiliates, and all users who
            access our Services through such account to these Terms, in which case the terms "you",
            "your" and “user” shall refer to such entity, its affiliates, and users associated with
            it.
          </FirstLevel>
          <MainHeading mt={4}>3. Disclaimer</MainHeading>
          <FirstLevel mt={2}>
            a. The materials on INTEGRTR's website are provided on an 'as is' basis. INTEGRTR makes
            no warranties, expressed or implied, and hereby disclaims and negates all other
            warranties including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or non-infringement of intellectual
            property or other violation of rights.
          </FirstLevel>
          <FirstLevel>
            b. Further, INTEGRTR does not warrant or make any representations concerning the
            accuracy, likely results, or reliability of the use of the materials on its website or
            otherwise relating to such materials or on any sites linked to this site.
          </FirstLevel>
          <MainHeading mt={4}>4. Intellectual property</MainHeading>
          <FirstLevel ml={0} mt={2}>
            INTEGRTR alone shall own all right, title and interest, including all related
            intellectual property rights, in and to the Website, Application, Services, INTEGRTR
            Content and any suggestions, ideas, enhancement requests, feedback, recommendations or
            other information provided by you or any other party relating to the Website and
            Application (collectively “Feedback”). You are not required to provide any Feedback to
            the INTEGRTR. To the extent you do provide any Feedback to INTEGRTR, you agree to assign
            and hereby do assign to INTEGRTR all right, title and interest in and to such Feedback,
            and you do hereby waive and renounce any moral rights you may have in the Feedback in
            favor of INTEGRTR, and agree that INTEGRTR may freely utilize such Feedback without
            compensation to you. This Agreement is not a sale and does not convey to you any rights
            of ownership in or related to the Website, Application or Services, or any intellectual
            property rights owned by INTEGRTR.
          </FirstLevel>
          <MainHeading mt={4}>5. Limitations</MainHeading>
          <FirstLevel ml={0} mt={2}>
            In no event shall INTEGRTR or its suppliers be liable for any damages (including,
            without limitation, damages for loss of data or profit, or due to business interruption)
            arising out of the use or inability to use the materials on INTEGRTR's website, even if
            INTEGRTR or a INTEGRTR authorized representative has been notified orally or in writing
            of the possibility of such damage. Because some jurisdictions do not allow limitations
            on implied warranties, or limitations of liability for consequential or incidental
            damages, these limitations may not apply to you.
          </FirstLevel>
          <MainHeading ml={0} mt={2}>
            6. Accuracy of materials
          </MainHeading>
          <FirstLevel ml={0} mt={2}>
            The materials appearing on INTEGRTR's website could include technical, typographical, or
            photographic errors. INTEGRTR does not warrant that any of the materials on its website
            are accurate, complete or current. INTEGRTR may make changes to the materials contained
            on its website at any time without notice. However, INTEGRTR does not make any
            commitment to update the materials.
          </FirstLevel>
          <MainHeading ml={0} mt={2}>
            7. Links
          </MainHeading>
          <FirstLevel ml={0} mt={2}>
            INTEGRTR has not reviewed all of the sites linked to its website and is not responsible
            for the contents of any such linked site. The inclusion of any link does not imply
            endorsement by INTEGRTR of the site. Use of any such linked website is at the user's own
            risk.
          </FirstLevel>
          <MainHeading mt={4}>8. Modifications</MainHeading>
          <FirstLevel ml={0} mt={2}>
            INTEGRTR may revise these terms of service for its website at any time without notice.
            By using this website, you are agreeing to be bound by the then current version of these
            terms of service.
          </FirstLevel>
          <MainHeading mt={4}>9. Governing Law</MainHeading>
          <FirstLevel ml={0} mt={2}>
            These terms and conditions are governed by and construed in accordance with the laws of
            Germany and you irrevocably submit to the exclusive jurisdiction of the courts in that
            State or location.
          </FirstLevel>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}
