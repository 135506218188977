import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import { Form } from 'react-final-form';
import LanguageInput from './LanguageInput';

export default function ExportDialog({ isOpen, onClose, onDownload, languages }) {
  return (
    <AlertDialog isOpen={isOpen} onClose={onClose}>
      <AlertDialogOverlay />

      <AlertDialogContent>
        <Form
          initialValues={{
            ...languages.reduce((acc, cur) => {
              acc[`lang_${cur}`] = true;
              return acc;
            }, {}),
          }}
          onSubmit={(state) => {
            const supportedLanguages = languages.filter((l) => !!state[`lang_${l}`]);
            if (supportedLanguages.length === 0) {
              onDownload();
            } else {
              onDownload(supportedLanguages);
            }
            onClose();
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <AlertDialogBody>
                <Text fontWeight="bold" mt={3}>
                  What are the languages you want to export out?
                </Text>

                <Box mt={4}>
                  <LanguageInput namePrefix="lang_" disableTags shownLanguages={languages} />
                </Box>
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={onClose}>Close</Button>
                <Button ml={2} variantColor="green" type="submit">
                  Download Selected
                </Button>
              </AlertDialogFooter>
            </form>
          )}
        </Form>
      </AlertDialogContent>
    </AlertDialog>
  );
}
