import { Box, Button, Flex, Text } from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import github from '../assets/github.svg';
import axios from '../axios';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';

export default function ReauthorizeGithubPage() {
  const [isRedirecting, setIsRedirecting] = React.useState(false);

  const startGithubLogin = async () => {
    setIsRedirecting(true);

    try {
      const res = await axios.get('/api/github/login');
      if (res.data.redirectTo) {
        navigate(res.data.redirectTo);
      }
    } finally {
      setIsRedirecting(false);
    }
  };

  return (
    <Layout>
      <PageTitle
        backTo="/"
        title={<FormattedMessage id="REAUTHORIZE_GITHUB" />}
        subtitle={<FormattedMessage id="REAUTHORIZE_GITHUB_SUBTITLE" />}
      />

      <Box mt={4} mx={8}>
        <Flex height={500} alignItems="center" justifyContent="center" flexDirection="column">
          <Box height={300}>
            <Box as="img" src={github} height="100%" />
          </Box>

          <Text mt={4} width={['80%', '70%', '50%']} textAlign="center" color="gray.500">
            <FormattedMessage id="GITHUB_NOT_LINKED" />
          </Text>
          <Text mt={1} width={['80%', '70%', '50%']} textAlign="center">
            <FormattedMessage id="REAUTHORIZE_GITHUB_REASON" />
          </Text>

          <Button
            mx={8}
            mt={2}
            disabled={isRedirecting}
            variantColor="green"
            onClick={startGithubLogin}
          >
            {isRedirecting ? (
              <FormattedMessage id="REDIRECTING" />
            ) : (
              <FormattedMessage id="LOGIN_GITHUB" />
            )}
          </Button>
        </Flex>
      </Box>
    </Layout>
  );
}
