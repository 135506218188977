import { Button, useDisclosure } from '@chakra-ui/core';
import { useParams } from '@reach/router';
import React from 'react';
import { GoMarkGithub } from 'react-icons/go';
import { MdOpenInNew } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useEffectOnce } from 'react-use';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import RepoUpdateConfirmation from '../components/RepoUpdateConfirmation';
import SelectProject from '../components/SelectProject';
import SEO from '../components/SEO';
import { useFetchProject } from '../store/project';

export default function ChangeGithubRepoPage() {
  const { projectId } = useParams();

  const [github, setGithub] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const currentProject = useSelector((state) => state.project.currentProject);
  const { fetchProject } = useFetchProject();

  useEffectOnce(() => {
    fetchProject({ projectId });
  });

  return (
    <Layout>
      <FormattedMessage
        id="CHANGE_GITHUB_REPO_N"
        values={{
          name: currentProject?.project?.name ? ` - ${currentProject?.project?.name}` : '',
        }}
      >
        {(value) => <SEO title={value} />}
      </FormattedMessage>

      <PageTitle
        title={
          <FormattedMessage
            id="CHANGE_GITHUB_REPO_N"
            values={{
              name: currentProject?.project?.name ? ` - ${currentProject?.project?.name}` : '',
            }}
          />
        }
        subtitle={<FormattedMessage id="CHANGE_GITHUB_REPO_SUBTITLE" />}
        backTo={`/projects/${projectId}/customize`}
        append={
          <Button
            display={['none', null, 'flex']}
            leftIcon={GoMarkGithub}
            rightIcon={MdOpenInNew}
            isDisabled={!currentProject?.project?.repoUrl}
            onClick={() => window.open(currentProject.project.repoUrl, '_blank')}
          >
            <FormattedMessage id="CURRENT_GITHUB_REPO" />
          </Button>
        }
      />

      <SelectProject
        subtitle={<FormattedMessage id="SELECT_NEW_GITHUB_REPO" />}
        onSelect={(gh) => {
          setGithub(gh);
          onOpen();
        }}
      />

      <RepoUpdateConfirmation github={github} isOpen={isOpen} onClose={onClose} />
    </Layout>
  );
}
