import axios from 'axios';
import firebase from 'firebase/app';

const client = axios.create({
  responseType: 'json',
});

// Append the github access token on each request.
client.interceptors.request.use(async (config) => {
  if (!config.headers) {
    config.headers = {};
  }

  const token = await firebase.auth().currentUser?.getIdToken();
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
});

export default client;
