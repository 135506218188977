import { Box, Button, Flex, Link, Text, useToast } from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { GoMarkGithub } from 'react-icons/go';
import { MdOpenInNew } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import github from '../assets/github.svg';
import axios from '../axios';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import SelectProject from '../components/SelectProject';
import SEO from '../components/SEO';
import { useUnlinkGithub } from '../store/auth';
import gh from '../store/github';
import NotMyAccountConfirmation from '../components/NotMyAccountConfirmation';

export default function AddProjectPage() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const pushToast = useToast();

  const startGithubLogin = async () => {
    setIsRedirecting(true);

    try {
      const res = await axios.get('/api/github/login');
      if (res.data.redirectTo) {
        navigate(res.data.redirectTo);
      }
    } finally {
      setIsRedirecting(false);
    }
  };

  const { unlinkGithub } = useUnlinkGithub();

  const unlink = React.useCallback(async () => {
    const res = await unlinkGithub();
    if (res.ok) {
      pushToast({
        description:
          'Successfully unlinked github. Relogin with a separate account by logging out from Github.',
        status: 'success',
      });
      return;
    }

    pushToast({
      description: 'Could not unlink github. Try again.',
      status: 'error',
    });
  }, [pushToast, unlinkGithub]);

  return (
    <Layout>
      <FormattedMessage id="ADD_PROJECT">{(value) => <SEO title={value} />}</FormattedMessage>

      <PageTitle
        title={<FormattedMessage id="ADD_PROJECT" />}
        subtitle={<FormattedMessage id="ADD_PROJECT_SUBTITLE" />}
        backTo="/"
      />
      {!auth.github && (
        <Flex height={500} alignItems="center" justifyContent="center" flexDirection="column">
          <Box height={300}>
            <Box as="img" src={github} height="100%" />
          </Box>

          <Text mt={4} width={['80%', '70%', '50%']} textAlign="center" color="gray.500">
            <FormattedMessage id="GITHUB_NOT_LINKED" />
          </Text>
          <Text mt={1} width={['80%', '70%', '50%']} textAlign="center">
            <FormattedMessage id="NEED_GITHUB" />
          </Text>

          <Button
            mx={8}
            mt={2}
            onClick={startGithubLogin}
            disabled={isRedirecting}
            variantColor="green"
          >
            {isRedirecting ? (
              <FormattedMessage id="REDIRECTING" />
            ) : (
              <FormattedMessage id="LOGIN_GITHUB" />
            )}
          </Button>
        </Flex>
      )}

      {auth.github && (
        <>
          <Box mt={4}>
            <Text display="block" px={[4, null, 8]}>
              <FormattedMessage id="GITHUB_LOGIN_SUCCESS" />
            </Text>
          </Box>

          <Box mt={1} mb={1} px={[4, null, 8]}>
            <Link
              href={`https://www.github.com/${auth.github.username}`}
              target="_blank"
              color="green.600"
            >
              <Box as={GoMarkGithub} display="inline" /> {auth.github.username}{' '}
              <Box as={MdOpenInNew} display="inline" />
            </Link>
            <NotMyAccountConfirmation onOk={unlink} />
          </Box>

          <SelectProject
            subtitle={<FormattedMessage id="SELECT_ONE_PROJECT_TO_TRANSLATE" />}
            onSelect={(repo) => {
              dispatch(gh.actions.setNewProject(repo));
              navigate('/add-project/configure');
            }}
          />
        </>
      )}
    </Layout>
  );
}
