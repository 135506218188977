import {
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useUpdateUser } from '../store/auth';
import * as v from '../validations';

export default function UserUpdateForm() {
  const auth = useSelector((state) => state.auth);
  const { state, updateUser } = useUpdateUser();
  const pushToast = useToast();

  return (
    <Form
      initialValues={{ name: auth.name ?? '' }}
      onSubmit={async (state) => {
        const res = await updateUser({ name: state.name.trim() });
        if (res.ok) {
          pushToast({
            description: 'Successfully updated profile.',
            status: 'success',
          });
          return;
        }

        pushToast({
          description: 'Failed to update profile.',
          status: 'error',
        });
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            validate={v.validateName}
            render={({ input, meta }) => (
              <FormControl isInvalid={meta.touched && meta.error}>
                <FormLabel htmlFor="name">
                  <FormattedMessage id="NAME" />
                </FormLabel>
                <Input placeholder="Your name" id="name" {...input} />
                {meta.touched && meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
              </FormControl>
            )}
          />

          <FormControl mt={4} isDisabled>
            <FormLabel htmlFor="email">
              <FormattedMessage id="EMAIL" />
            </FormLabel>
            <FormattedMessage id="EMAIl">
              {(value) => <Input placeholder={value} id="email" value={auth.email} />}
            </FormattedMessage>
            <FormHelperText>
              <FormattedMessage id="CANNOT_CHANGE_EMAIL" />
            </FormHelperText>
          </FormControl>

          <Button mt={4} type="submit" variantColor="green" isLoading={state.loading}>
            <FormattedMessage id="SAVE" />
          </Button>
        </form>
      )}
    </Form>
  );
}
