import { Box, Flex } from '@chakra-ui/core';
import React from 'react';

export default function SectionedLayout({ left, right }) {
  return (
    <Flex minHeight="100vh" flexDirection={['column-reverse', null, null, 'row']}>
      <Flex
        flexDirection="column"
        width={['100%', null, null, '40%']}
        pt={[0, null, null, 4]}
        px={4}
        bg="green.500"
      >
        {left}
      </Flex>

      <Box width={['100%', null, null, '60%']} px={6} textAlign="center" bg="white">
        {right}
      </Box>
    </Flex>
  );
}
