import { Flex, Box, Button, Heading, Text } from '@chakra-ui/core';
import { Link as Lnk } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

import imageSrc from '../assets/undraw_page_not_found_su7k.svg';
import Image from '../components/Image';

export default function NotFoundPage() {
  return (
    <Layout>
      <SEO title="Not Found" />
      <Flex width="100%" height="100%" justify="center" alignItems="center" px={8} pt={6}>
        <Box textAlign="center">
          <Image src={imageSrc} maxHeight={240} />
          <Box my={5}>
            <Heading size="lg">
              <FormattedMessage id="NOT_FOUND" />
            </Heading>
            <Text>
              <FormattedMessage id="NOT_FOUND_MESSAGE" />
            </Text>
            <Button as={Lnk} to="/" mt={6} variantColor="green">
              <FormattedMessage id="GO_HOME" />
            </Button>
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
}
