import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Text,
} from '@chakra-ui/core';
import { DisplayNames } from '@formatjs/intl-displaynames/lib';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import helpMessage from '../assets/help-message.svg';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import SEO from '../components/SEO';
import { translatableLanguages } from '../store/translate';

function QuestionAnswer({ questionId, answerId }) {
  return (
    <AccordionItem>
      <Box px={8} pt={4} pb={2}>
        <AccordionHeader>
          <Text textAlign="left" fontWeight="bold">
            <FormattedMessage id={questionId} />
          </Text>
        </AccordionHeader>
        <AccordionPanel isOpen>
          <FormattedMessage id={answerId} />
        </AccordionPanel>
      </Box>
    </AccordionItem>
  );
}

export default function HelpPage() {
  const intl = useIntl();

  return (
    <Layout>
      <FormattedMessage id="HELP">{(value) => <SEO title={value} />}</FormattedMessage>
      <PageTitle title={<FormattedMessage id="HELP" />} backTo="/" />

      <Flex justifyContent="center" height="300px" py={16}>
        <Image src={helpMessage} alt="help message" height="100%" />
      </Flex>

      <Flex justifyContent="center">
        <Button
          as="a"
          href="mailto:sales@integrtr.com?subject=INTEGRTR%20%7C%20Translations%20Query&body=Hello%2C%0AI%20would%20like%20to%20know%20more%20about%20Integrtr%20Translations.%0A%20%0AEmail%20address%20to%20be%20contacted%3A%20%E2%80%A6%0AName%3A%0ARole%3A%0ACompany%20Name%3A%20%E2%80%A6%0ACountry%3A%0AComments%2FQuestions%3A%0A%20%0AThanks%20!"
          variantColor="green"
        >
          <FormattedMessage id="EMAIL_US" />
        </Button>
      </Flex>
      <Box textAlign="center" mt={4}>
        <Text px={8} color="gray.500" display="inline-block" width={['90%', '80%', '70%', '50%']}>
          <FormattedMessage id="EMAIL_US_INFO" />
        </Text>
      </Box>
      <Heading as="h5" size="md" mt={12} px={8}>
        <FormattedMessage id="FAQ" />
      </Heading>
      <Accordion mt={4} mb={12} allowMultiple>
        <QuestionAnswer questionId="FAQ_APP_FREE_Q" answerId="FAQ_APP_FREE_A" />
        <QuestionAnswer questionId="FAQ_CREATE_NEW_PROJ_Q" answerId="FAQ_CREATE_NEW_PROJ_A" />
        <QuestionAnswer questionId="FAQ_RELOGIN_GITHUB_Q" answerId="FAQ_RELOGIN_GITHUB_A" />
        <QuestionAnswer
          questionId="SUPPORT_AUTOMATIC_TRANSLATIONS_Q"
          answerId="SUPPORT_AUTOMATIC_TRANSLATIONS_MESSAGE"
        />
        <AccordionItem>
          <Box px={8} pt={4} pb={2}>
            <AccordionHeader>
              <Text textAlign="left" fontWeight="bold">
                <FormattedMessage id="SUPPORTED_AUTOMATIC_LANGUAGES_Q" />
              </Text>
            </AccordionHeader>
            <AccordionPanel isOpen>
              <FormattedMessage id="SUPPORTED_AUTOMATIC_LANGUAGES_MESSAGE" />
              <Box as="ul" pt={1}>
                {translatableLanguages.map((lang) => (
                  <Text as="li" ml={8} key={lang}>
                    {new DisplayNames(intl.locale).of(lang)}
                  </Text>
                ))}
              </Box>
            </AccordionPanel>
          </Box>
        </AccordionItem>
      </Accordion>
    </Layout>
  );
}
