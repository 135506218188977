import { Box, Tag, Text } from '@chakra-ui/core';
import React from 'react';

// SAP properties file comment special code.
const xact = {
  XALT: 'Alternative text',
  XBCB: 'Breadcrumb step',
  XBLI: 'Bullet list item text',
  XBUT: 'Button text',
  XCAP: 'Caption',
  XCEL: 'Cell',
  XCKL: 'Checkbox',
  XCOL: 'Column header',
  XCRD: 'Tabstrip',
  XDAT: 'Data navigation text',
  XFLD: 'Label',
  XFRM: 'Frame',
  XGLS: 'Term',
  XGRP: 'Group title',
  XHED: 'Heading',
  XLGD: 'Legend text',
  XLNK: 'Hyperlink text',
  XLOG: 'Log entry',
  XLST: 'List box item',
  XMEN: 'Menu header',
  XMIT: 'Menu item',
  XMSG: 'Message text',
  XRBL: 'Radio button',
  XRMP: 'Roadmap step',
  XROW: 'Table row heading',
  XSEL: 'Selection text',
  XTBS: 'Tab strip text',
  XTIT: 'Table title',
  XTND: 'Tree node text',
  XTOL: 'Quick info text',
  XTXT: 'General text',
};
const xactKeys = Object.keys(xact);

export default function CommentRow({ comment = '' }) {
  let commentString = comment;
  const codeSection = comment.substring(0, 5);
  let code;
  if (xactKeys.includes(codeSection.substring(0, 4)) && codeSection.endsWith(':')) {
    code = codeSection.substring(0, 4);
    commentString = commentString.substring(5).trim();
  }

  return (
    <Box>
      {code && (
        <Tag size="sm" textTransform="none" mr={2} bg="gray.400" color="white">
          {xact[code]}
        </Tag>
      )}
      <Text as="code" fontFamily="monospace" color="gray.500">
        {commentString}
      </Text>
    </Box>
  );
}
