import React from 'react';
import { Helmet } from 'react-helmet';

export default function SEO({ description = '', lang = 'en', title }) {
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`Integrtr Translations Hub | %s`}
      meta={[
        {
          name: `description`,
          content: description,
        },
      ]}
    />
  );
}
