import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useToast,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import config from '../config';
import {
  useFetchGithubInstallations,
  useFetchGithubRepos,
  useCheckInstallation,
} from '../store/github';
import GithubRepoCard from './GithubRepoCard';

export default function SelectProject({ onSelect, subtitle }) {
  const pushToast = useToast();
  const [dirtyInstallations, setDirtyInstallations] = React.useState(true);
  const [dirtyRepos, setDirtyRepos] = React.useState(true);
  const [installationId, setInstallationId] = React.useState(null);

  const repos = useSelector((state) => state.github.repos);
  const installations = useSelector((state) => state.github.installations);
  const { state: installationState, fetchGithubInstallations } = useFetchGithubInstallations();
  const { state: repoState, fetchGithubRepos } = useFetchGithubRepos();
  const { checkInstallation } = useCheckInstallation();

  // Get the installations of the user.
  React.useEffect(() => {
    if (dirtyInstallations) {
      fetchGithubInstallations();
      setDirtyInstallations(false);
      setDirtyRepos(true);

      const installationId = localStorage.getItem('integrtr_t9n_installation_id');
      if (installationId) {
        checkInstallation({
          installationId: localStorage.getItem('integrtr_t9n_installation_id'),
        })
          .then(async (res) => {
            if (res.installationStatus === 'not_found') {
              pushToast({
                description:
                  'You are trying to configure the app for unknown github account. Make sure you are logged in with the correct github account.',
                // Give more time to consume the error.
                duration: 10000,
                status: 'error',
              });
            }
          })
          .finally(() => {
            localStorage.removeItem('integrtr_t9n_installation_id');
          });
      }
    }
  }, [
    dirtyInstallations,
    setDirtyInstallations,
    setDirtyRepos,
    fetchGithubInstallations,
    checkInstallation,
    pushToast,
  ]);

  // Get the  github repos of this installation.
  React.useEffect(() => {
    if (dirtyRepos) {
      if (installationId) {
        fetchGithubRepos({ installationId });
      }
      setDirtyRepos(false);
    }
  }, [dirtyRepos, setDirtyRepos, installationId, fetchGithubRepos]);

  // Set the default installation id if one is not selected.
  React.useEffect(() => {
    if (
      installations.length > 0 &&
      (!installationId || !installations.find((inst) => inst.installationId === installationId))
    ) {
      setInstallationId(installations[0].installationId);
      setDirtyRepos(true);
    }
  }, [installationId, setInstallationId, installations]);

  // If github token revoked error happens, redirect to reauthorize github page.
  React.useEffect(() => {
    if (
      installationState.error?.response?.data?.type === 'github_token_revoked' ||
      repoState.error?.response?.data?.type === 'github_token_revoked'
    ) {
      pushToast({
        description: 'Github integration with this has been revoked.',
        status: 'error',
      });
      navigate('/reauthorize-github');
    }
  }, [pushToast, installationState.error, repoState.error]);

  const onConfigureGithub = () => {
    const w = 1012;
    const h = 800;
    const left = window.screen.width / 2 - (w / 2 + 10);
    const top = window.screen.height / 2 - (h / 2 + 50);

    const newWindow = window.open(
      `https://github.com/apps/${config.GITHUB_APP_NAME}/installations/new`,
      'Configure Github App',
      `status=no,height=${h},width=${w},resizable=yes,left=${left},top=${top},screenX=${left},` +
        `screenY=${top},toolbar=no,menubar=no,scrollbars=no,location=no,directories=no`
    );

    // Reload the github installations when the popup is closed.
    const inter = setInterval(() => {
      if (newWindow.closed) {
        setDirtyInstallations(true);
        clearInterval(inter);
      }
    }, 300);

    if (window.focus) {
      newWindow.focus();
    }

    return false;
  };

  return (
    <Box mt={8}>
      <Flex justifyContent="space-between" px={[4, null, 8]}>
        <Box>
          <Heading as="h4" fontSize={16}>
            <FormattedMessage id="YOUR_GITHUB_PROJECTS" />
          </Heading>
          <Text color="gray.500">{subtitle}</Text>
        </Box>

        <Box>
          {installations.length > 0 && (
            <Menu>
              <MenuButton as={Button} rightIcon="chevron-down">
                {installations.find((inst) => inst.installationId === installationId)?.login}
              </MenuButton>
              <MenuList placement="bottom-end">
                {installations.map((inst) => (
                  <MenuItem
                    key={inst.installationId}
                    onClick={() => {
                      setInstallationId(inst.installationId);
                      setDirtyInstallations(true);
                    }}
                  >
                    {inst.login}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          )}
        </Box>
      </Flex>
      <Divider />

      <Box mt={3} px={[4, null, 8]}>
        {(installationState.loading || repoState.loading) &&
          Array(5)
            .fill(0)
            .map((_, index) => <Skeleton key={index} height={20} my={4} />)}

        {!installationState.loading && installationState.error && (
          <Box backgroundColor="red.200" px={3} py={3} borderRadius={4}>
            <Text color="red.500">
              <FormattedMessage id="GITHUB_REPO_FETCH_FAILED" />
            </Text>
          </Box>
        )}

        {!installationState.loading && !installationState.error && installations.length === 0 && (
          <>
            <Box backgroundColor="#f0f0f0" px={3} py={6} borderRadius={4}>
              <Text color="gray.500" textAlign="center">
                <FormattedMessage id="NO_REPO_AUTHORIZED" />
              </Text>

              <Flex justifyContent="center" mt={2}>
                <Button variantColor="green" onClick={onConfigureGithub}>
                  <FormattedMessage id="CONFIGURE_ON_GITHUB" />
                </Button>
              </Flex>
            </Box>
          </>
        )}

        {!installationState.loading && !installationState.error && installations.length > 0 && (
          <>
            {!repoState.loading && !repoState.error && repos.length === 0 && (
              <Box backgroundColor="#f0f0f0" px={3} py={3} borderRadius={4}>
                <Text color="gray.500">
                  <FormattedMessage id="NO_REPO_IN_ACCOUNT" />
                </Text>
              </Box>
            )}

            {!repoState.loading && repoState.error && (
              <Box backgroundColor="red.200" px={3} py={3} borderRadius={4}>
                <Text color="red.500">
                  <FormattedMessage id="GITHUB_NETWORK_ERROR" />
                </Text>
              </Box>
            )}

            {!repoState.loading && repos.length > 0 && (
              <>
                {repos.map((r) => (
                  <GithubRepoCard
                    key={r.fullName}
                    name={r.fullName}
                    description={r.description}
                    my={4}
                    onClick={() => onSelect({ ...r, installationId })}
                  />
                ))}

                <Text>
                  <FormattedMessage id="CANT_SEE_REPO" />{' '}
                  <Link color="green.500" onClick={onConfigureGithub}>
                    <FormattedMessage id="CONFIGURE_GITHUB_APP" />
                  </Link>
                </Text>
              </>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
