import { charsets, PasswordPolicy } from 'password-sheriff';

// Unnecessarily lints as wrong with `unnecessary escapes`.
// eslint-disable-next-line
const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const validateEmail = (val) => {
  if (!val || val.length === 0) {
    return 'Email is required.';
  }

  if (!emailRegex.test(val)) {
    return 'Email is invalid.';
  }
};

const passwordPolicy = new PasswordPolicy({
  contains: {
    expressions: [charsets.upperCase, charsets.numbers, charsets.lowerCase],
  },
});

export const validatePassword = (val) => {
  if (!val || val.length === 0) {
    return 'Password is required.';
  }

  if (val.length < 8) {
    return 'Password should be at least 8 characters long.';
  }

  if (!passwordPolicy.check(val)) {
    return 'Password should have at least a lower-case letter, an upper-case letter, and a number.';
  }
};

export const validatePasswordLiberal = (val) => {
  if (!val || val.length === 0) {
    return 'Password is required.';
  }
};

export const validateName = (val) => {
  if (!val || val.trim().length === 0) {
    return 'Name is required.';
  }
};
