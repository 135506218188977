import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useEffectOnce } from 'react-use';
import logo from '../assets/logo_outline.png';
import logoDark from '../assets/logo_dark.png';
import AppInfo from '../components/AppInfo';
import Image from '../components/Image';
import PasswordReset from '../components/PasswordReset';
import SectionedLayout from '../components/SectionedLayout';
import SEO from '../components/SEO';
import { useResetPassword } from '../store/auth';

export default function PasswordResetPage({ code }) {
  const { verifyState, verifyResetCode } = useResetPassword();

  useEffectOnce(() => {
    verifyResetCode({ code });
  });

  return (
    <>
      <FormattedMessage id="PASSWORD_RESET">{(value) => <SEO title={value} />}</FormattedMessage>
      <SectionedLayout
        left={
          <>
            <Box zIndex={100}>
              <Image src={logo} height={12} display={['none', null, null, 'block']} />
            </Box>

            <Flex
              px={8}
              pt={[8, null, null, 0]}
              pb={[0, null, null, 32]}
              mt={[4, null, null, 0]}
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              {verifyState.loading && (
                <>
                  <Spinner color="white" />
                </>
              )}

              {!verifyState.loading && verifyState.error && (
                <Box textAlign="center">
                  <Text color="white" bg="red.500" fontSize="lg">
                    {verifyState.error?.message ?? <FormattedMessage id="PASSWORD_NOT_CHANGED" />}
                  </Text>
                  <Button mt={4} onClick={() => navigate('/')}>
                    <FormattedMessage id="GO_HOME" />
                  </Button>
                </Box>
              )}

              {!verifyState.loading && !verifyState.error && <PasswordReset code={code} />}
            </Flex>
          </>
        }
        right={
          <>
            <Image src={logoDark} mt={4} height={12} display={['block', null, null, 'none']} />
            <AppInfo />
          </>
        }
      />
    </>
  );
}
