import { useLocation } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SEO from '../components/SEO';

export default function FinishInstallationPage() {
  const { search } = useLocation();

  React.useEffect(() => {
    const installationId = new URLSearchParams(search).get('installation_id');
    localStorage.setItem('integrtr_t9n_installation_id', installationId);

    // The installation has finished. Since this page is most-likely opened in a popup, close
    // it.
    window.close();
  });

  return (
    <>
      <SEO title="Github Redirecting..." /> <FormattedMessage id="REDIRECTING_TO_APP" />
    </>
  );
}
