import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useEffectOnce } from 'react-use';
import logo from '../assets/logo_outline.png';
import logoDark from '../assets/logo_dark.png';
import AppInfo from '../components/AppInfo';
import Image from '../components/Image';
import SectionedLayout from '../components/SectionedLayout';
import SEO from '../components/SEO';
import { useEmailVerification } from '../store/auth';

export default function VerifyEmailPage({ code }) {
  const { state, verifyEmail } = useEmailVerification();

  useEffectOnce(() => {
    verifyEmail({ code });
  });

  return (
    <>
      <FormattedMessage id="VERIFY_EMAIL">{(value) => <SEO title={value} />}</FormattedMessage>
      <SectionedLayout
        left={
          <>
            <Box zIndex={100}>
              <Image src={logo} height={12} display={['none', null, null, 'block']} />
            </Box>

            <Flex
              px={8}
              pt={[8, null, null, 0]}
              pb={[0, null, null, 32]}
              mt={[4, null, null, 0]}
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <Box textAlign="center">
                {state.loading && (
                  <>
                    <Spinner color="white" />
                    <Text color="white" fontSize="lg">
                      <FormattedMessage id="VERIFYING_WAIT" />
                    </Text>
                  </>
                )}
                {!state.loading && !state.error && (
                  <>
                    <Text color="white" fontSize="lg">
                      <FormattedMessage id="EMAIL_VERIFIED" />
                    </Text>
                    <Button mt={4} onClick={() => navigate('/login')}>
                      <FormattedMessage id="LOGIN_NOW" />
                    </Button>
                  </>
                )}

                {!state.loading && state.error && (
                  <>
                    <Text color="white" bg="red.500" fontSize="lg">
                      {state.error?.message ?? <FormattedMessage id="EMAIL_NOT_VERIFIED" />}
                    </Text>
                    <Button mt={4} onClick={() => navigate('/')}>
                      <FormattedMessage id="GO_HOME" />
                    </Button>
                  </>
                )}
              </Box>
            </Flex>
          </>
        }
        right={
          <>
            <Image src={logoDark} mt={4} height={12} display={['block', null, null, 'none']} />
            <AppInfo />
          </>
        }
      />
    </>
  );
}
