import firebase from 'firebase/app';
import 'firebase/auth';
import config from './config';

// Read the config from the var. The value is expected to be a json string.
firebase.initializeApp(config.FIREBASE_CONFIG);

const tokenKey = 'integrtr-translations-auth-token';

/// DEPRECATED: Remove this function after all the users have logged out once, so that the token key is
/// removed from local storage.
/// TODO: This can be removed in a week from now.
export function forgetAuthToken() {
  localStorage.removeItem(tokenKey);
}
