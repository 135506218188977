import { navigate } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';

export default function PrivateRoute({ component, ...rest }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  React.useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated]);

  const Comp = component;
  return isAuthenticated ? <Comp {...rest} /> : <div />;
}
