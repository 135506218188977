import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  IconButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { MdEmail } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useResendInvitationEmail } from '../store/project';

export default function InvitationEmailConfirmation({ projectId, email }) {
  const cancelRef = React.useRef();
  const { state, resendInvitationEmail } = useResendInvitationEmail();
  const pushToast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <IconButton
        icon={MdEmail}
        bg="transparent"
        borderRadius="100%"
        _active={{
          bg: 'transparent',
        }}
        _hover={{
          bg: 'transparent',
        }}
        width={5}
        height={5}
        minWidth={null}
        display="flex"
        mx={2}
        onClick={onOpen}
      />

      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
        <AlertDialogOverlay />
        <AlertDialogCloseButton />
        <AlertDialogContent>
          <AlertDialogHeader>
            <FormattedMessage id="RESEND_INVITATION_EMAIL_Q" />
          </AlertDialogHeader>
          <AlertDialogBody>
            <FormattedMessage id="RESEND_INVITATION_EMAIL_MESSAGE" values={{ email }} />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isDisabled={state.loading}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button
              variantColor="red"
              isLoading={state.loading}
              onClick={async () => {
                const res = await resendInvitationEmail({
                  email,
                  projectId,
                });
                if (res.ok) {
                  pushToast({
                    description: 'Successfully sent invitation email.',
                    status: 'success',
                  });
                  onClose();
                  return;
                }
                pushToast({
                  description: 'Failed to send invitation email.',
                  status: 'error',
                });
              }}
              ml={3}
            >
              <FormattedMessage id="OK" />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
