import { navigate } from '@reach/router';
import React from 'react';
import { useSelector } from 'react-redux';

/// This route is only to be shown to those people who are not authorized. For ex: login, signup, etc.
export default function UnauthorizedOnlyRoute({ component, ...rest }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  React.useEffect(() => {
    if (isAuthenticated) {
      // If authenticated go to home page.
      navigate('/');
    }
  }, [isAuthenticated]);

  const Comp = component;
  return isAuthenticated ? <div /> : <Comp {...rest} />;
}
