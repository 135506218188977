const firebaseConfig = {
  apiKey: 'AIzaSyBlZBgL5sYp-99nMc8njiqkzEomybLnnU0',
  authDomain: 'integrtr-translation.firebaseapp.com',
  databaseURL: 'https://integrtr-translation.firebaseio.com',
  projectId: 'integrtr-translation',
  storageBucket: 'integrtr-translation.appspot.com',
  messagingSenderId: '182058019753',
  appId: '1:182058019753:web:26a8f7957a7e10983372b8',
  measurementId: 'G-Y0BJW0Q2LT',
};

const config = {
  GITHUB_APP_NAME: 'translation-assistant', // process.env.REACT_APP_GITHUB_APP_NAME,
  FIREBASE_CONFIG: firebaseConfig, // JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
};

export default config;
