import { combineReducers, configureStore } from '@reduxjs/toolkit';
import auth from './auth';
import github from './github';
import locale from './locale';
import project from './project';

const store = configureStore({
  reducer: combineReducers({
    locale: locale.reducer,
    github: github.reducer,
    auth: auth.reducer,
    project: project.reducer,
  }),
});

export default store;
