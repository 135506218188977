import { Box, Flex, Heading, Image } from '@chakra-ui/core';
import styled from '@emotion/styled';
import { Link } from '@reach/router';
import React from 'react';

const CardWrapper = styled(Flex)`
  &:hover {
    background-color: #fafafa;
    box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.1);
  }
`;

export default function ActionCard({ imageSrc, text, mx, to }) {
  return (
    <CardWrapper
      as={Link}
      to={to}
      width={200}
      height={200}
      boxShadow="0 0 8px 2px rgba(0,0,0,.1)"
      mx={mx}
      flexDirection="column"
      alignItems="center"
      borderRadius={4}
      overflow="hidden"
    >
      <Flex flex={1} alignItems="center">
        <Image src={imageSrc} maxWidth={140} maxHeight={140} />
      </Flex>

      <Box py={3} bg="green.500" width="100%">
        <Heading as="h6" size="sm" textAlign="center" color="white">
          {text}
        </Heading>
      </Box>
    </CardWrapper>
  );
}
