import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/core';
import { Link as Lnk, navigate } from '@reach/router';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FcGoogle } from 'react-icons/all';
import { FormattedMessage } from 'react-intl';
import languages from '../assets/languages.svg';
import { useLogin, useResendVerificationEmail, useLoginWithGoogle } from '../store/auth';
import * as v from '../validations';
import Image from './Image';

export default function Login() {
  const { state, login } = useLogin();
  const { state: googleState, loginWithGoogle } = useLoginWithGoogle();
  const pushToast = useToast();
  const [unverifiedUser, setUnverifiedUser] = React.useState(null);

  const { state: resendState, called, resendVerificationEmail } = useResendVerificationEmail();

  return (
    <Flex justifyContent="center">
      <Box
        width={[400, 440, null, 360, 440]}
        shadow="lg"
        px={8}
        py={12}
        rounded="md"
        zIndex={100}
        bg="white"
      >
        <Heading as="h3" size="xl" color="gray.800">
          <FormattedMessage id="LOG_IN" />
        </Heading>

        <Form
          initialValues={{ email: '', password: '' }}
          onSubmit={async (state) => {
            setUnverifiedUser(null);
            const res = await login({ username: state.email, password: state.password });
            if (res.emailUnverified) {
              setUnverifiedUser({
                email: state.email,
                password: state.password,
              });
              return;
            }

            if (res.ok) {
              navigate('/');
            }
          }}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="email"
                validate={v.validateEmail}
                render={({ input, meta }) => (
                  <FormControl mt={12} isInvalid={meta.touched && meta.error}>
                    <FormLabel htmlFor="email" color="gray.500" fontWeight="bold">
                      <FormattedMessage id="EMAIL" />
                    </FormLabel>
                    <Input
                      id="email"
                      size="lg"
                      placeholder="you@example.com"
                      borderTop="none"
                      borderLeft="none"
                      borderRight="none"
                      borderRadius={0}
                      _focus={{
                        boxShadow: 'none',
                        borderColor: 'green.500',
                      }}
                      {...input}
                    />
                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              <Field
                name="password"
                validate={v.validatePasswordLiberal}
                render={({ input, meta }) => (
                  <FormControl mt={4} isInvalid={meta.touched && meta.error}>
                    <Flex justifyContent="space-between">
                      <FormLabel htmlFor="password" color="gray.500" fontWeight="bold">
                        <FormattedMessage id="PASSWORD" />
                      </FormLabel>

                      <Link
                        as={Lnk}
                        to="/forgot-password"
                        color="green.500"
                        fontSize="sm"
                        fontWeight="bold"
                      >
                        <FormattedMessage id="FORGOT_PASSWORD" />
                      </Link>
                    </Flex>

                    <FormattedMessage id="ENTER_PASSWORD">
                      {(value) => (
                        <Input
                          id="password"
                          type="password"
                          size="lg"
                          placeholder={value}
                          borderTop="none"
                          borderLeft="none"
                          borderRight="none"
                          borderRadius={0}
                          _focus={{
                            boxShadow: 'none',
                            borderColor: 'green.500',
                          }}
                          {...input}
                        />
                      )}
                    </FormattedMessage>

                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              {!state.loading && state.error && (
                <Text color="red.500" mt={2}>
                  {state.error?.description ?? state.error?.message ?? (
                    <FormattedMessage id="SOMETHING_BAD_HAPPENED" />
                  )}
                </Text>
              )}

              {unverifiedUser && !called && (
                <>
                  <Text color="red.500" mt={2}>
                    <FormattedMessage id="UNVERIFIED_ERROR" />
                  </Text>

                  <Button
                    mt={2}
                    variantColor="blueGray"
                    isLoading={resendState.loading}
                    onClick={async () => {
                      const res = await resendVerificationEmail({
                        email: unverifiedUser.email,
                        password: unverifiedUser.password,
                      });
                      if (res.ok) {
                        pushToast({
                          description: 'Successfully sent the verification email.',
                          status: 'success',
                        });
                        setUnverifiedUser(null);
                        return;
                      }
                    }}
                  >
                    Resend verification code
                  </Button>
                </>
              )}

              <Button
                type="submit"
                isLoading={state.loading}
                isDisabled={resendState.loading || googleState.loading}
                mt={6}
                width="100%"
                variantColor="green"
              >
                <FormattedMessage id="LOGIN" />
              </Button>
            </form>
          )}
        </Form>

        <Box mt={4}>
          <Button
            width="100%"
            isLoading={googleState.loading}
            isDisabled={resendState.loading || state.loading}
            onClick={loginWithGoogle}
          >
            <Box as={FcGoogle} mr={3} fontSize={20} /> <FormattedMessage id="LOGIN_WITH_GOOGLE" />
          </Button>
        </Box>

        <Box mt={6}>
          <Text as="span">
            <FormattedMessage id="DONT_HAVE_ACCOUNT" />
          </Text>
          <Link as={Lnk} to="/signup" display="inline" ml={2} fontWeight="bold" color="green.500">
            <FormattedMessage id="CREATE_FREE_ACCOUNT" />
          </Link>
        </Box>
      </Box>

      <Box position="absolute" bottom={12} left={12} display={['none', null, null, 'block']}>
        <Box width={300}>
          <Image src={languages} />
        </Box>
      </Box>
    </Flex>
  );
}
