import { Box, Flex } from '@chakra-ui/core';
import React from 'react';
import logo from '../assets/logo_outline.png';
import logoDark from '../assets/logo_dark.png';
import AppInfo from '../components/AppInfo';
import ForgotPassword from '../components/ForgotPassword';
import Image from '../components/Image';
import SectionedLayout from '../components/SectionedLayout';
import SEO from '../components/SEO';
import { FormattedMessage } from 'react-intl';

export default function ForgotPasswordPage() {
  return (
    <>
      <FormattedMessage id="FORGOT_PASSWORD">{(value) => <SEO title={value} />}</FormattedMessage>
      <SectionedLayout
        left={
          <>
            <Box>
              <Image src={logo} height={12} display={['none', null, null, 'block']} />
            </Box>

            <Flex
              px={8}
              pt={[8, null, null, 0]}
              pb={[0, null, null, 32]}
              mt={[4, null, null, 0]}
              alignItems="center"
              justifyContent="center"
              flex={1}
            >
              <ForgotPassword />
            </Flex>
          </>
        }
        right={
          <>
            <Image src={logoDark} mt={4} height={12} display={['block', null, null, 'none']} />
            <AppInfo />
          </>
        }
      />
    </>
  );
}
