import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Input,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDeleteProject } from '../store/project';

export default React.forwardRef(function ConfirmationButton(props, ref) {
  const pushToast = useToast();
  const currentProject = useSelector((state) => state.project.currentProject);
  const projectId = currentProject?.project?._id;
  const projectName = currentProject?.project?.name;

  const { isOpen, onClose, onOpen } = useDisclosure();
  const cancelRef = React.useRef();

  const { state, deleteProject } = useDeleteProject();

  const [deleteName, setDeleteName] = React.useState('');
  const onDelete = React.useCallback(async () => {
    if (projectName !== deleteName) {
      return;
    }

    await deleteProject({ projectId });
    pushToast({
      description: 'Successfully deleted the project.',
      status: 'success',
    });
    navigate('/');
  }, [deleteProject, projectId, pushToast, projectName, deleteName]);

  return (
    <>
      <Button ref={ref} variantColor="red" onClick={onOpen} {...props}>
        <FormattedMessage id="DELETE_PROJECT" />
      </Button>

      <AlertDialog
        isOpen={isOpen}
        onClose={state.loading ? null : onClose}
        leastDestructiveRef={cancelRef}
      >
        <AlertDialogOverlay />
        <AlertDialogContent borderRadius="md" pt={4} pb={2}>
          <AlertDialogBody>
            <FormattedMessage id="DELETE_PROJECT_CONFIRMATION" />
            <FormattedMessage id="PROJECT_NAME_ENTER">
              {(value) => (
                <Input
                  mt={4}
                  placeholder={value}
                  value={deleteName}
                  onChange={(ev) => setDeleteName(ev.target.value)}
                />
              )}
            </FormattedMessage>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose} isDisabled={state.loading}>
              <FormattedMessage id="CANCEL" />
            </Button>
            <Button variantColor="red" isLoading={state.loading} onClick={onDelete} ml={3}>
              <FormattedMessage id="OK" />
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
});
