import {
  Box,
  Flex,
  Heading,
  IconButton,
  Link as Lnk,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { navigate } from '@reach/router';
import React from 'react';
import { GoGitPullRequest, GoMarkGithub } from 'react-icons/go';
import { MdArrowForward, MdHelp, MdTranslate, MdOpenInNew } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import addProjectImage from '../assets/add-project.png';
import prImage from '../assets/pr.png';
import translateImage from '../assets/translate.png';
import Image from './Image';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

const DisabledTab = React.forwardRef(({ onSelect, ...props }, ref) => (
  <Box
    {...props}
    onClick={(ev) => {
      ev.preventDefault();
      onSelect();
    }}
  />
));

export default function AppInfo() {
  const [index, setIndex] = React.useState(0);
  const { isOpen: privacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { isOpen: tosOpen, onOpen: onTosOpen, onClose: onTosClose } = useDisclosure();

  return (
    <Box minHeight={[null, null, 'calc(100vh - 4rem)']} py={4}>
      <Box>
        <Flex justifyContent="flex-end">
          <Box
            as="a"
            href="https://www.producthunt.com/posts/translation-hub?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-translation-hub"
            target="_blank"
            rel="noopener noreferrer"
            mr={2}
          >
            <img
              src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=270590&theme=light"
              alt="Translation Hub - Multi-lingual applications made easy | Product Hunt"
              style={{ width: 250, height: 54 }}
              width={250}
              height={54}
            />
          </Box>

          <IconButton
            icon={MdHelp}
            fontSize={24}
            size="sm"
            bg="white"
            onClick={() => navigate('/help')}
          />
        </Flex>
        <Heading mt={4}>
          <FormattedMessage id="TRANSLATE_BREEZE" />
        </Heading>
        <Text color="gray.500" fontSize="lg" mt={[2, null, 0]}>
          <FormattedMessage id="TRANSLATE_PRIVATE_PUBLIC" />
        </Text>

        <Tabs mt={6} index={index} onChange={(idx) => setIndex(idx)} variantColor="green">
          <TabList justifyContent="center">
            <Tab>
              <Flex flexDirection="column" alignItems="center">
                <Box as={GoMarkGithub} fontSize={[24, null, 32]} width={['56px', null, 108]} />
                <Text mt={2} color="gray.500" display={['none', null, 'block']}>
                  <FormattedMessage id="PULL_GITHUB" />
                </Text>
              </Flex>
            </Tab>
            <DisabledTab
              as={MdArrowForward}
              fontSize={[24, null, 32]}
              width={['56px', null, 108]}
              onSelect={() => setIndex(2)}
            />
            <Tab>
              <Flex flexDirection="column" alignItems="center" width={['56px', null, 108]}>
                <Box as={MdTranslate} fontSize={[24, null, 32]} />
                <Text mt={2} color="gray.500" display={['none', null, 'block']}>
                  <FormattedMessage id="TRANSLATE" />
                </Text>
              </Flex>
            </Tab>
            <DisabledTab
              as={MdArrowForward}
              fontSize={[24, null, 32]}
              width={['56px', null, 108]}
              onSelect={() => setIndex(4)}
            />
            <Tab>
              <Flex flexDirection="column" alignItems="center" width={['56px', null, 108]}>
                <Box as={GoGitPullRequest} fontSize={[24, null, 32]} />
                <Text mt={2} color="gray.500" display={['none', null, 'block']}>
                  <FormattedMessage id="CREATE_PR" />
                </Text>
              </Flex>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Flex justifyContent="center" p={4} bg="gray.100">
                <Image src={addProjectImage} height={[200, null, '45vh']} />
              </Flex>
            </TabPanel>
            <TabPanel />
            <TabPanel>
              <Flex justifyContent="center" p={4} bg="gray.100">
                <Image src={translateImage} height={[200, null, '45vh']} />
              </Flex>
            </TabPanel>
            <TabPanel />
            <TabPanel>
              <Flex justifyContent="center" p={4} bg="gray.100">
                <Image src={prImage} height={[200, null, '45vh']} />
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      <Box mt={4}>
        <Lnk
          href="https://www.integrtr.com/using-translation-hub-with-react/"
          target="_blank"
          rel="noopener noreferrer"
          fontSize="lg"
          color="green.500"
          fontWeight="semibold"
          display="inline-flex"
          alignItems="center"
        >
          <Box as="span" mr={2}>
            <MdOpenInNew />
          </Box>
          Learn: Using Translation Hub with ReactJS{' '}
        </Lnk>
      </Box>

      <Box mt={[12, null, null, 6]} width="100%">
        <Lnk color="green.500" mr={3} onClick={onPrivacyOpen}>
          <FormattedMessage id="PRIVACY_POLICY" />
        </Lnk>
        <Lnk color="green.500" ml={3} onClick={onTosOpen}>
          <FormattedMessage id="TOS" />
        </Lnk>
      </Box>

      <PrivacyPolicy isOpen={privacyOpen} onClose={onPrivacyClose} />
      <TermsAndConditions isOpen={tosOpen} onClose={onTosClose} />
    </Box>
  );
}
