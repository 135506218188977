import {
  Avatar,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import { Link, navigate } from '@reach/router';
import React from 'react';
import { FaSign, FaUserSecret } from 'react-icons/fa';
import { MdExitToApp, MdLanguage, MdSettings, MdHelp } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import logo from '../assets/logo.png';
import { useLogout } from '../store/auth';
import ChangeLanguage from './ChangeLanguage';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

export default function Navigation({ middle }) {
  const { isOpen: privacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { isOpen: tosOpen, onOpen: onTosOpen, onClose: onTosClose } = useDisclosure();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const name = useSelector((state) => state.auth.name);
  const { logout } = useLogout();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Flex
        as="nav"
        py={3}
        bg="blueGray.700"
        justifyContent="center"
        boxShadow="0 2px 6px rgba(0,0,0,.16)"
        position="sticky"
        top={0}
        zIndex={1000}
      >
        <Flex
          width="100%"
          maxWidth={1280}
          px={4}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box as={Link} to="/" height={6}>
            <img alt="logo" src={logo} style={{ height: '100%' }} />
          </Box>

          {middle}

          <Box>
            {isAuthenticated && (
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon="chevron-down"
                  bg="transparent"
                  color="white"
                  _hover={{
                    backgroundColor: 'transparent',
                  }}
                  _active={{
                    backgroundColor: 'transparent',
                  }}
                >
                  <Avatar width={6} height={6} />
                  <Text fontWeight="bold" color="white" ml={2}>
                    {name?.split(/[ @]/g)?.[0] ?? ''}
                  </Text>
                </MenuButton>

                <MenuList placement="bottom-end" width={100}>
                  <MenuItem onClick={onOpen}>
                    <Box as={MdLanguage} mr={3} />
                    <FormattedMessage id="LANGUAGE" />
                  </MenuItem>
                  <MenuItem onClick={() => navigate('/settings')}>
                    <Box as={MdSettings} mr={3} />
                    <FormattedMessage id="SETTINGS" />
                  </MenuItem>
                  <MenuItem
                    as="a"
                    href="mailto:translation-hub@integrtr.com?subject=Support%20Request"
                  >
                    <Box as={MdHelp} mr={3} />
                    <FormattedMessage id="CONTACT_SUPPORT" />
                  </MenuItem>
                  <MenuItem onClick={onPrivacyOpen}>
                    <Box as={FaUserSecret} mr={3} />
                    <FormattedMessage id="PRIVACY_POLICY" />
                  </MenuItem>
                  <MenuItem onClick={onTosOpen}>
                    <Box as={FaSign} mr={3} />
                    <FormattedMessage id="TOS" />
                  </MenuItem>
                  <MenuItem onClick={logout}>
                    <Box as={MdExitToApp} mr={3} />
                    <FormattedMessage id="LOGOUT" />
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Box>
        </Flex>
      </Flex>

      <ChangeLanguage isOpen={isOpen} onClose={onClose} />
      <PrivacyPolicy isOpen={privacyOpen} onClose={onPrivacyClose} />
      <TermsAndConditions isOpen={tosOpen} onClose={onTosClose} />
    </>
  );
}
