import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSendDeleteRequest } from '../store/auth';

export default function DeleteAccountConfirmation({ isOpen, onClose }) {
  const cancelRef = React.useRef();
  const { state, sendDeleteRequest } = useSendDeleteRequest();
  const pushToast = useToast();

  return (
    <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef}>
      <AlertDialogOverlay />
      <AlertDialogCloseButton />
      <AlertDialogContent>
        <AlertDialogHeader>
          <FormattedMessage id="DELETE_ACCOUNT_CONFIRM_Q" />
        </AlertDialogHeader>
        <AlertDialogBody>
          <FormattedMessage id="DELETE_ACCOUNT_CONFIRM_MESSAGE" />
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose} isDisabled={state.loading}>
            <FormattedMessage id="CANCEL" />
          </Button>
          <Button
            variantColor="red"
            isLoading={state.loading}
            onClick={async () => {
              const res = await sendDeleteRequest();
              if (res.ok) {
                pushToast({
                  description: 'Successfully sent delete request for the user.',
                  status: 'success',
                });
                onClose();
                return;
              }
              pushToast({
                description: 'Failed to send a delete request for the user.',
                status: 'error',
              });
            }}
            ml={3}
          >
            <FormattedMessage id="OK" />
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
