import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/core';
import { Link as Lnk, navigate } from '@reach/router';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import languages from '../assets/languages.svg';
import { useSignup } from '../store/auth';
import * as v from '../validations';
import Image from './Image';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

const validateName = (val) => {
  if (!val || val.length === 0) {
    return 'Name is required.';
  }
};

export default function Signup() {
  const { state, signup } = useSignup();
  const pushToast = useToast();

  const { isOpen: privacyOpen, onOpen: onPrivacyOpen, onClose: onPrivacyClose } = useDisclosure();
  const { isOpen: tosOpen, onOpen: onTosOpen, onClose: onTosClose } = useDisclosure();

  return (
    <Flex justifyContent="center">
      <Box
        width={[400, 440, null, 360, 440]}
        shadow="lg"
        px={8}
        py={12}
        rounded="md"
        zIndex={100}
        bg="white"
      >
        <Heading as="h3" size="xl" color="gray.800">
          <FormattedMessage id="SIGN_UP" />
        </Heading>

        <Form
          initialValues={{
            name: '',
            email: '',
            password: '',
            agree: false,
          }}
          onSubmit={async (state, form) => {
            const res = await signup(state);
            if (res.ok) {
              pushToast({
                description: 'Successfully signed up. Please verify your email before logging in.',
                status: 'success',
              });

              navigate('/login');
            }
          }}
        >
          {({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="name"
                validate={validateName}
                render={({ input, meta }) => (
                  <FormControl mt={12} isInvalid={meta.touched && meta.error}>
                    <FormLabel htmlFor="name" color="gray.500" fontWeight="bold">
                      <FormattedMessage id="NAME" />
                    </FormLabel>
                    <FormattedMessage id="YOUR_NAME">
                      {(value) => (
                        <Input
                          id="name"
                          size="lg"
                          placeholder={value}
                          borderTop="none"
                          borderLeft="none"
                          borderRight="none"
                          borderRadius={0}
                          _focus={{
                            boxShadow: 'none',
                            borderColor: 'green.500',
                          }}
                          {...input}
                        />
                      )}
                    </FormattedMessage>

                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              <Field
                name="email"
                validate={v.validateEmail}
                render={({ input, meta }) => (
                  <FormControl mt={4} isInvalid={meta.touched && meta.error}>
                    <FormLabel htmlFor="email" color="gray.500" fontWeight="bold">
                      <FormattedMessage id="EMAIL" />
                    </FormLabel>
                    <Input
                      id="email"
                      size="lg"
                      placeholder="you@example.com"
                      borderTop="none"
                      borderLeft="none"
                      borderRight="none"
                      borderRadius={0}
                      _focus={{
                        boxShadow: 'none',
                        borderColor: 'green.500',
                      }}
                      {...input}
                    />

                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              <Field
                name="password"
                validate={v.validatePassword}
                render={({ input, meta }) => (
                  <FormControl mt={4} isInvalid={meta.touched && meta.error}>
                    <FormLabel htmlFor="password" color="gray.500" fontWeight="bold">
                      <FormattedMessage id="PASSWORD" />
                    </FormLabel>
                    <FormattedMessage id="ENTER_PASSWORD">
                      {(value) => (
                        <Input
                          id="password"
                          type="password"
                          size="lg"
                          placeholder={value}
                          borderTop="none"
                          borderLeft="none"
                          borderRight="none"
                          borderRadius={0}
                          _focus={{
                            boxShadow: 'none',
                            borderColor: 'green.500',
                          }}
                          {...input}
                        />
                      )}
                    </FormattedMessage>

                    {meta.touched && meta.error && (
                      <FormErrorMessage>{meta.error}</FormErrorMessage>
                    )}
                  </FormControl>
                )}
              />

              {!state.loading && state.error && (
                <Text color="red.500" mt={2}>
                  {state.error?.description ?? state.error?.message ?? (
                    <FormattedMessage id="SOMETHING_BAD_HAPPENED" />
                  )}
                </Text>
              )}

              <Field
                name="agree"
                type="checkbox"
                render={({ input }) => (
                  <Checkbox
                    mt={6}
                    color="gray.700"
                    isChecked={input.value}
                    onChange={input.onChange}
                  >
                    <FormattedMessage id="I_AGREE_TO" />{' '}
                    <Link
                      color="green.500"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onTosOpen();
                      }}
                    >
                      <FormattedMessage id="TOS" />
                    </Link>{' '}
                    <FormattedMessage id="AND" />{' '}
                    <Link
                      color="green.500"
                      onClick={(ev) => {
                        ev.preventDefault();
                        onPrivacyOpen();
                      }}
                    >
                      <FormattedMessage id="PRIVACY_POLICY" />
                    </Link>
                    .
                  </Checkbox>
                )}
              />

              <Button
                type="submit"
                mt={4}
                isLoading={state.loading}
                isDisabled={!values.agree}
                width="100%"
                variantColor="green"
              >
                <FormattedMessage id="CREATE_FREE_ACCOUNT" />
              </Button>
            </form>
          )}
        </Form>

        <Box mt={6}>
          <Text as="span">
            <FormattedMessage id="ALREADY_HAVE_ACCOUNT" />
          </Text>
          <Link as={Lnk} to="/login" display="inline" ml={2} fontWeight="bold" color="green.500">
            <FormattedMessage id="LOGIN" />
          </Link>
        </Box>
      </Box>

      <Box position="absolute" bottom={12} left={12} display={['none', null, null, 'block']}>
        <Box width={300}>
          <Image src={languages} />
        </Box>
      </Box>

      <PrivacyPolicy isOpen={privacyOpen} onClose={onPrivacyClose} />
      <TermsAndConditions isOpen={tosOpen} onClose={onTosClose} />
    </Flex>
  );
}
