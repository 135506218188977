import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
  Button,
  Flex,
  Image,
  Text,
  useToast,
} from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import createPrImage from '../assets/create_pr.svg';
import prInProgress from '../assets/pr_in_progress.svg';
import { useCreatePR } from '../store/project';

export default function CreatePR({ projectId, isOpen, onClose: _onClose }) {
  const cancelRef = React.useRef();

  const pushToast = useToast();
  const [url, setUrl] = React.useState('');
  const { state, createPR } = useCreatePR();
  const [shouldForce, setShouldForce] = React.useState(false);

  const onClose = React.useCallback(() => {
    _onClose();
    // Reset the url and force.
    setUrl('');
    setShouldForce(false);
  }, [_onClose, setUrl]);

  const onOK = React.useCallback(async () => {
    const res = await createPR({ projectId, force: shouldForce });
    if (res.prUrl) {
      setUrl(res.prUrl);
      return;
    }

    if (res.noPr) {
      pushToast({
        description: 'No changes in the PR.',
        status: 'warning',
      });
      onClose();
    }
  }, [createPR, projectId, pushToast, shouldForce, onClose]);

  React.useEffect(() => {
    if (!state.loading && state.error && state.error?.response?.data?.type !== 'project_outdated') {
      pushToast({
        description:
          state.error?.response?.data?.message ?? state.error?.message ?? 'Something bad happened.',
        status: 'error',
      });
      onClose();
    }
  }, [state.loading, state.error, pushToast, onClose]);

  React.useEffect(() => {
    if (!state.loading && state.error && state.error?.response?.data?.type === 'project_outdated') {
      setShouldForce(true);
    }
  }, [state.loading, state.error, setShouldForce]);

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={state.loading ? null : onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" pt={4} pb={2}>
        <AlertDialogBody>
          {!url && shouldForce && !state.loading && (
            <Text mb={2}>
              <FormattedMessage id="PR_FORCE_CREATE_SURE" />
            </Text>
          )}

          {!url && !state.loading && (
            <Text>
              <FormattedMessage id="PR_CREATE_SURE" />
            </Text>
          )}

          {!url && state.loading && (
            <Text>
              <FormattedMessage id="PR_CREATING_WAIT" />
            </Text>
          )}

          {url && (
            <Text>
              <FormattedMessage id="PR_CREATED" />
            </Text>
          )}

          <Flex justifyContent="center" mt={4}>
            {!url && !state.loading && <Image src={createPrImage} height={160} />}
            {!url && state.loading && <Image src={prInProgress} height={160} />}
          </Flex>
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} isDisabled={state.loading} onClick={onClose}>
            <FormattedMessage id="CANCEL" />
          </Button>
          {!url && (
            <Button
              variantColor={shouldForce ? 'red' : 'green'}
              onClick={onOK}
              isLoading={state.loading}
              ml={3}
            >
              <FormattedMessage id="OK" />
            </Button>
          )}

          {url && (
            <Button
              variantColor="green"
              ml={3}
              onClick={() => {
                window.open(url, '_blank');
                onClose();
              }}
            >
              <FormattedMessage id="VIEW_PR" />
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
