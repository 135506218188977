import { Box, Heading } from '@chakra-ui/core';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DeleteAccount from '../components/DeleteAccount';
import Layout from '../components/Layout';
import PageTitle from '../components/PageTitle';
import SocialLinking from '../components/SocialLinking';
import UserUpdateForm from '../components/UserUpdateForm';

export default function SettingsPage() {
  return (
    <Layout>
      <PageTitle
        title={<FormattedMessage id="SETTINGS" />}
        subtitle={<FormattedMessage id="SETTINGS_SUBTITLE" />}
        backTo="/"
      />
      <Box mx={4} mt={4}>
        <Heading as="h5" fontSize="lg" mb={4}>
          <FormattedMessage id="PERSONAL_INFO" />
        </Heading>
        <UserUpdateForm />
      </Box>

      <SocialLinking />
      <DeleteAccount />
    </Layout>
  );
}
