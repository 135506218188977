import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Flex,
  Image,
  Text,
  Spinner,
} from '@chakra-ui/core';
import React from 'react';
import creatingProjectImage from '../assets/creating_project.svg';

export default function NewProjectProgress({ isLoading }) {
  return (
    <AlertDialog isOpen={isLoading}>
      <AlertDialogOverlay />
      <AlertDialogContent borderRadius="md" pt={4} pb={4}>
        <AlertDialogBody>
          <Flex justifyContent="center">
            <Image src={creatingProjectImage} height={160} />
          </Flex>
          <Flex justifyContent="center" mt={4}>
            <Spinner color="green.500" />
          </Flex>
          <Text textAlign="center" mt={2}>
            Setting up the project for you...
          </Text>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
}
